
.slideSwiper {
    margin-top: 3em;

    &__title {
        font-weight: bold;        
    }
}

.sliderSwiper {
    &__cont {
        margin: 50px 0;
        background-color: #f6f6f6;
        padding: 40px!important;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 30px;
            top: 10px;
            width: 85px;
            height: 100px;
            background-image: linear-gradient(to left, #f7f7f7 50%, rgba(255,255,255,0));
            display: none;

            @media (min-width: 992px){
                display: inline-block;
            }
        }

        &:before {
            content: '\f105';
            position: absolute;
            right: 30px;
            top: 40px;
            font-family: fontAwesome;
            font-size: 20px;
            color: #c2c2c2;
            z-index: 10;
            display: none;
        }
        
        .slider-for-prev,
        .slider-nav-prev,
        .slider-for-next,
        .slider-nav-next,
        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: 50%;
            display: inline-block;
            font-family: FontAwesome;
            font-size: 42px;
            color: #c2c2c2;
            z-index: 10;

            @media (min-width: 992px){
                font-size: 80px;
            }
            
            &:hover {
                color: rgba(#c2c2c2,.8);
                cursor: pointer;
            }
        }
        .slider-for-next,
        .slider-nav-next,
        .swiper-button-next {
            right: 15px;

            @media (min-width: 992px){
                right: 40px;
            }

            &:after {
                content: '\f105';       
            }
        }
        .slider-for-prev,
        .slider-nav-prev,
        .swiper-button-prev {
            left: 15px;

            @media (min-width: 992px){
                left: 40px;
            }

            &:after {
                content: '\f104';
            }
        }
        .slider-nav-prev,
        .slider-nav-next {
            font-size: 20px;
            top: 6%;
        }
        .slider-nav-prev {
            left: 20px;
        }                
    }

    &__item {
        padding: 0 10px;

        @media (min-width: 992px){
            padding: 0 80px;
        }

        // section for slider
        .min-width {
            max-width: 970px;
            margin: auto;
            margin-bottom: 25px;
        }

        .division {
            display: flex;
            flex-direction: column;    

            /*@media (min-width: 1024px){
                flex-direction: row;
                align-items: flex-start;

                > img,
                > div {
                    width: 50%;
                }
            }*/

            &__text {
                margin-top: 30px;

                @media (min-width: 1024px){
                    max-width: 350px;
                    margin: 0 auto;
                }

                h4 {
                    margin-bottom: 25px;
                }
                ul {
                    padding-left: 15px;

                    > li {
                        > strong {
                            font-size: 18px;

                            @media (min-width: 992px){
                                font-size: 22px;
                            }   
                        }

                        > span {
                            font-size: 18px;
                        }

                        &::marker {
                            font-size: 24px;

                            @media (min-width: 992px){
                                font-size: 32px;
                            }
                        }
                    }
                }
            }

            &__buttons {
                //margin-top: 30px;

                a {
                    margin-right: 25px;
                    padding-left: 23px;
                    font-size: 18px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .division--flex {
            //flex-direction: row;
            align-items: flex-start;
            row-gap: 20px;

            @media (min-width: 768px){
                flex-direction: row;
                row-gap: none;
            }

            > * {
                flex: 0 0 100%;
                max-width: 100%;

                @media (min-width: 768px){
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
            
            .img-flex {
                flex: 0 0 100%;
                max-width: 100%;
                width: auto;
                padding-right: 30px;

                @media (min-width: 768px){
                    flex: 0 0 70%;
                    max-width: 70%;

                    .pimcore_area_sliderSwiper &{
                        flex: 0 0 60%;
                        max-width: 60%;
                    }
                }

                @media (min-width: 1200px){
                    flex: 0 0 75%;
                    max-width: 75%;

                    .pimcore_area_sliderSwiper &{
                        flex: 0 0 65%;
                        max-width: 65%;
                    }
                }
            }

            label {
                font-size: 16px;
                font-weight: bold;
                display: block;

                @media (min-width: 768px){
                    font-size: 22px;
                    font-weight: 400;
                }
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
         }

        .division__catalog {
            flex: 0 0 100%;
            max-width: 100%;
            row-gap: 20px;

            @media (min-width: 769px){
                flex: 0 0 30%;
                max-width: 30%;
                row-gap: 0px;

                .pimcore_area_sliderSwiper & {
                    flex: 0 0 40%;
                    max-width: 40%;
                }
            }

            @media (min-width: 1200px) {
                flex: 0 0 25%;
                max-width: 25%;

                .pimcore_area_sliderSwiper &{
                    flex: 0 0 35%;
                    max-width: 35%;
                }
            }

            label {
                margin-bottom: 10px;

                @media (min-width: 768px){
                    margin-bottom: 20px;
                }
            }

            img {
                margin-bottom: 5px;
                max-width: 230px;
                display: none;

                @media (min-width: 768px) {
                    display: inline-block;
                }
            }
        }

        .division__material {
            ul li:after {
                content: ', ';
                margin-right: 2px;
            }
            ul li:last-child:after {
                content: none;
            }
        }
    }

    &__pag {
        width: 100%;
        margin-bottom: 54px;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 4px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f0f0f0;
            height: 4px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #ccc;
            height: 4px;
            cursor: -webkit-grabbing; 
            cursor: grabbing;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(#ccc,.8);
            height: 4px;
            cursor: -webkit-grabbing; 
            cursor: grabbing;
        }

        > span {
            flex: 0 0 auto;
        }

        .swiper-pagination-bullet {
            //margin-right: 30px;
            margin-bottom: 15px;
            outline: none;

            > span {
                @extend .btn;
                @extend .btn--link;

                width: 82%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                transition: border .25s ease 0s;
            }

            &:hover {
                > span {
                    border-color: #033c73;
                }            
            }

            &:last-child {
                margin-right: 0;
            }
        }
        .swiper-pagination-bullet.slick-current {
            > span {
                border-color: #033c73;
            }            
        }

    }
}

.swiper-slide__title {
    margin-bottom: 30px;
    margin-top: 0;
}

.nav-sector {

    > .row {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        background-color: #f0f0f0;
        padding: 30px;
        position: relative;
        text-align: center;

        @media (min-width: 767px){
            text-align: left;
        }

        .item-title {
            font-size: 18px;
            display: block;
            margin-bottom: 30px;
            //line-height: 1.4;

            @media (min-width: 767px){
                font-size: 20px;
            }
        }

        &.type-2 {
            background-color: transparent;
            border: 2px solid #f0f0f0;
        }

        .btn {
            font-size: 16px;

            @media (min-width: 767px){
                font-size: 18px;
            }
        }
        
        &:hover {
            .btn {
                color: #033c73;
                background-color: transparent;
            }
        }
    }
}

.slideSwiper__brick {
    &__title {
        margin-bottom: 40px;
    }

    .swiper-slide__title {
        display: none;
    }

    .sliderSwiper__cont {
        background-color: transparent;
        border-top: 1px solid #ccc;

        &::after {
            display: none;
        }
    }

    .border-top0 {
        border-top: 0px;
    }

    .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        margin: 0;
        padding: 0;
        height: 15px;

        > li.slick-active {
            opacity: 1;
        }
        > li {
            list-style: none;
            flex: 0 0 15px;
            max-width: 15px;
            height: 15px;
            opacity: .4;
        }
        
        button {
            border-radius: 50%;
            width: 15px;
            padding: 0px;
            margin: 0px;
            border: 0px;
            height: 15px;
            text-indent: -1999px;
            overflow: hidden;
            background-color: black;
        }
    }

    .sliderSwiper__cont {
        @media (max-width: 768px){
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
    }

    .sliderSwiper__item .division {
        @media (max-width: 768px){
            display: inline-block;

            .img-flex {
                margin-bottom: 16px;
                padding-right: 0px;
            }
        }
    }

    .sliderSwiper__cont .slider-for-prev {
        left: 0px;
    }
    .sliderSwiper__cont .slider-for-next {
        right: 0px;
    }
}

// anchor list 
.slider-anchor-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;

    //@media (min-width: 480px){
    //    justify-content: flex-start;
    //}

    @media (min-width: 992px){
        justify-content: space-between;
        column-gap: 30px;
        flex-wrap: nowrap;

        .a-landing & {
           flex-wrap: wrap;
           justify-content: center; 
        }
    }

    > * {
        @media (min-width: 480px){
            flex: 0 0 200px;
            max-width: 200px;

            .a-landing & {
                flex: 0 0 auto;
                max-width: unset;
             }

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-height: 46px;
        }
    }

    .btn--link {
       font-size: 16px;         
    }
}