
.a-landingprodotto .with-flex, 
.a-landing .with-flex {
    display: flex;    
}

.a-landingprodotto .site-nav, 
.a-landing .site-nav {
    float: none;
    justify-content: inherit;
    margin: 0;
}

.a-landingprodotto .carousel-block .item, 
.a-landing .carousel-block .item {
    text-align: center;
}
.a-landingprodotto .carousel-block .item, .a-landing .carousel-block .item,
.a-landingprodotto #carousel-homepage, .a-landing #carousel-homepage {
    max-height: inherit;
}

/* centered content column container */
.content-centered {
    margin: auto;
    display: flex;
    justify-content: center;
    float: none;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (min-width: 992px){
        max-width: 66.66667%
    }
}