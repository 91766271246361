.a-landingprodotto,
.a-landing {

    .site-head--top {
        overflow: hidden;
    }

    .with-flex {
        display: inherit;
    }

    .site-nav {
        float: right;
    }

    .site-nav__item {
        font-size: 16px;

        @media (max-width: 767px){
            height: 75px;
            line-height: 75px;
        }
    }

    &__content {
        min-height: 300px;
        margin: 25px auto;
    }

    // Carousel
    #carousel-homepage {
        max-height: 580px;
    }

    .carousel-control {
        display: none;
    }

    .carousel-block {
        width:100%;
    	overflow:hidden;

        .item {
            max-height: 580px;
        }

        @media (max-width: 767px){
            .carousel-indicators {
                display: block;
                bottom: 3px;
            }
        }
    }

    .carousel-caption {
        bottom: 80px;

        @media (max-width: 767px){
            bottom: 40px;
            display: block;
        }

        p {
            text-align: center;
            margin: auto;

            &.titolo-slider {
                font-size: 32px;
                line-height: 1.1;

                @media (max-width: 767px) {
                    font-size: 23px;
                }
            }

            &.content-slider {
                font-size: 16px!important;

                @media (max-width: 767px){
                    display: none;
                }
            }
        }
    }

    .area-gallery-folder {
        .thumbnail {
            margin: 0;
            padding: 0;
            border: none;

            img {
                //width: 100%;
            }
        }
        .zoomPopup {
            margin: 0;

            > div {
                padding: 0;
                //width: 16.66667%;
            }
        }
    }

    .big-background {
        //height: 60.42vh;

        .mb_YTVPPlaypause.is-played,
        .mb_YTVPPlaypause {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            font-size: 93px;
            margin-left: 0;

            @media (max-width: 767px){
                font-size: 63px;
            }

            &::before {
                width: 130px;
                height: 130px;
                line-height: 130px;
                position: absolute;
                bottom: 40px;
                margin-left: -65px;
                padding: 0;

                @media (max-width: 767px){
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    margin-left: -50px;
                    bottom: 20px;
                }
            }
        }

        .mb_YTVPPlaypause.is-played {
            font-size: 80px;

            @media (max-width: 767px){
                font-size: 50px;
            }
        }
    }
}

// Landing Careers
.a-landing {
    // Btn content
}

// Differnce of Landing prodotto
.a-landingprodotto {

    #carousel-homepage {
        max-height: inherit;
    }

    .carousel-block {
        .item {
            max-height: inherit;
        }
    }
}

.top-lang {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        float: left;
        margin-right: 15px;
    }

    &__item {
        @extend .site-head__toplink;
        text-transform: uppercase;

        &.active {
            color: $default;
        }
    }
}

// Nav ctas
.site-head__searchcont {
    .use-ctas & {
        width: auto!important;
    }
}

.site-head {
    &.use-ctas .row-fluid.with-flex {
        @media (max-width: 992px){
            flex-direction: column;
        }
    }
}

.landing-ctas {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;

    .btn {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: 992px){
            font-size: 12px;
            padding: 3px 16px!important;
        }
    }
}
