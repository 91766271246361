
.fancybox-close, .fancybox-prev, .fancybox-next {
    background-image: none!important;

    &:before {
		display: inline-block;
	    font-family: FontAwesome;
	    font-size: 30px;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		text-shadow: 0 0 10px rgba(0,0,0,.6);
	}
}

.fancybox-close {
    &:before {
        color: white;
        content: '\f00d';
    }
}

.fancybox-prev {
    &:before {
		content: '\f053';
		left: 0;
	}

	@media (max-width: 767px){
		left: 0;

		&:before {
			left: 10px;
		}
	}
}

.fancybox-next {
	&:before {
		content: '\f054';
		right: 0;
	}

	@media (max-width: 767px){
		right: 0;

		&:before {
			right: 10px;
		}
	}
}
