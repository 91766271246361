.map {
    &__controls {
        margin: 30px 0 15px;

        .btn {
            padding: 0 15px;
            cursor: pointer;
            /*height: 36px;
            line-height: 36px;*/
            font-size: 16px;
            line-height: 35px;
        }

        .btn > i {
            margin-right: 10px;
        }

        .btn--showall {
            display: none;
        }
    }

    &__finder {
        position: relative;
        float: right;

        @media (max-width: 767px){
            width: 100%;
            margin-top: 10px;
        }

        .fa {
            position: absolute;
            bottom: 10px;
            right: 0;
        }
    }

    &__search {
        width: 250px;
        padding: 5px 0;
        padding-right: 25px;
        outline: 0;

        @media (max-width: 767px){
            width: 100%;
        }
    }

    &__info {
        min-width: 170px;

        a,
        span {
            font-size: 14px;
            //font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
            //font-family: "Helvetica", Arial, sans-serif;
            position: relative;
            display: inline-block;
            padding-left: 20px;
            width: 100%;
            color: #333;

            &::before {
                font-family: 'fontAwesome';
                position: absolute;
                top: 0;
                left: 0;
                font-size: 15px;
                color: #033c73;
            }
        }

        a {
            color: #033c73;
        }

        img {
            width: 130px;
            margin-top: 3px;
            margin-bottom: 15px;
        }

        .business-logo {
            &:before {
                content: '';
                width: 13px;
                height: 12px;
                background-image: url('/website/static/images/scmgroup/maps-icon.png');
                background-size: 100%;
                background-position: center center;
                top: 5px;
            }
        }
    }
}
