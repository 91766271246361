.table-responsive {
	/*@media (max-width: 767px){
		border-right: 1px solid $secondary-color;
	}*/

	border-left: 1px solid rgba(0,0,0,0.25);
	border-right: 1px solid rgba(0,0,0,0.25);
	border-bottom: 1px solid rgba(0,0,0,0.25);
	width: 100%;
	margin-bottom: 30px;
}

.flat-table {
	//margin-bottom: 20px;
	border-collapse:collapse;
	//font-family: 'Lato', Calibri, Arial, sans-serif;
	border: none;
    //border-radius: 3px;
    background-color: $table-grey-bg;
	/*border-left: 1px solid rgba(0,0,0,0.25);
	border-right: 1px solid rgba(0,0,0,0.25);
	border-bottom: 1px solid rgba(0,0,0,0.25);*/
    width: 100%;
    margin-bottom: 0;

	@media (max-width: 767px){
		width: auto;
	}

    th {
        font-weight: normal;
    	-webkit-font-smoothing: antialiased;
    	padding: 1em;
    	//color: rgba(0,0,0,0.45);
    	text-shadow: 0 0 1px rgba(0,0,0,0.1);
    	//font-size: 1.5em;
    }

    td {
        //color: #f7f7f7;
    	padding: 0.6em 1em;
    	text-shadow: 0 0 1px rgba(255,255,255,0.1);
    	//font-size: 1.4em;
    }

    th, td {
        box-shadow: inset 0 1px rgba(0,0,0,0.25);
    }

    tr {
        -webkit-transition: background 0.3s, box-shadow 0.3s;
    	-moz-transition: background 0.3s, box-shadow 0.3s;
    	transition: background 0.3s, box-shadow 0.3s;
    }
}

.table-responsive {
	td img {
		max-width: inherit;
		width: auto;
		height: auto;
	}

	@media (max-width: 767px){
		border: none;
	}
}
