.l_calc__cont {
    max-width: 100%;
    margin: auto;
    margin-bottom: 40px;
    padding: 30px;
    border: none;
    background-color: #f5f5f5;

    .table-responsive {
        border: none;
    }

    h1 {
        color: #033c73;
    }

    hr {
        margin-top: 0;
        margin-bottom: 0;
        border-top: none;
        height: 2px;
        background-color: #cecece;
    }

    .l_min {
        color: #464646;
        font-size: initial;
    }

    .l_alert {
        clear: left;
        float: left;
        display: none;
    }

    .l_form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
        color: #333;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 0px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
        -o-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    }
    .l_fa {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
    }
    .l_calc__cont
    .l_calc__form label{
        float: left;
        font-weight: bold;
        width: 60%;
        color: #033c73;
        padding-right: 30px;
        padding-top: 10px;
    }
    .l_calc__input-cont{
        width: 100%;
        float: left;
    }
    .l_calc__input-cont input{
        width: 50%;
    }
}

.l_calc__cont
.l_value {
    text-align: right;
    width: 40%;
    height: 20px;
    padding: 5px 15px 5px 15px;
    //border-right: 2px solid #033c73;
    border-bottom: 2px solid #033c73;
}
.l_lowered_cost.l_value {
    background-color: #464646;
    color:#fff;
    border-right: none;
    border-bottom: none;
    font-weight: bold;
    font-size: 18px;
}
.l_calc {
    &__btn{
        background-color: #033c73;
        color: #fff;
        outline: none;
        border: none;
        padding: 10px 20px;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: bold;
    }
    &__input {
        width: 200px;
        margin-bottom: 10px;
        padding-left: 30px;
    }
    &__table {
        margin-top: 50px;
        width: 100%;
        border-spacing: 10px 10px;
        border-collapse: initial;
        background-color: transparent!important;

        td {
            box-shadow: none!important;
        }
    }
    &__table .l_lbl {
        font-weight: bold;
        text-align: right;
        width: 60%;
        color: #464646;
        padding-right: 30px;
    }
    &__table .l_lbl.l_big {
        font-size: 16px;
        color: #033c73;
    }
}
