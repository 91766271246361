
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=b3dc791e-2bea-4cd6-966c-f132dc156ef0");
@font-face{
    font-family:"Helvetica Neue";
    font-weight: 275;
    src:url("../../fonts/e206f16e-f3ae-45eb-b7a9-c0a42ed7537f.woff2") format("woff2"),
    url("../fonts/f4d1bafa-befd-4f3f-ba25-7c0f77c6651e.woff") format("woff");
    font-display: swap;
}
@font-face{
    font-family:"Helvetica Neue";
    font-weight: 300;
    src:url("../../fonts/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),
    url("../fonts/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff");
    font-display: swap;
}
@font-face{
    font-family:"Helvetica Neue";
    font-weight: 400;
    src:url("../../fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),
    url("../fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff");
    font-display: swap;
}