
.site-head {
    background-color: $white;
    position: relative;
    height: 100px;

    > .site-wrap {
        line-height: 100px;
    }

    .with-flex {
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 767px){
        width: 100%;
        height: 75px;
        line-height: 75px;

        /*position: fixed;
        top: 0;
        z-index: 1001;*/
        background-color: $white;

        > .site-wrap {
            line-height: inherit;
        }
    }

    &--top {
        background-color: $grey-bg;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
    }

    &__toplink {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $grey-foot;
        margin-right: 15px;

        @include transition(opacity .25s ease-in-out);

        .fa {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            opacity: .8;
        }

        &.phone-number {
            color: $primary-color;
        }
    }

    &__logo {
        padding-left: 15px;

        @media (max-width: 767px){
            padding-left: 0;
            float: none;
            text-align: center;
            margin: auto;
        }

        img {
            width: 168px;
            height: auto;

            @media (min-width: 1200px){
                width: 198px;
            }

            @media (min-width: 768px){
                .is-home-sector & {
                    width: 155px;
                }
            }

            @media (max-width: 767px){
                margin: auto;
                width: 140px;

                .is-sector & {
                    width: 120px;
                }
            }
        }
    }

    /*&__cont {
        height: 100px;
        line-height: 100px;
    }*/

    &__searchcont {
        width: auto;
        padding-right: 15px;
        
        display: flex;
        justify-content: flex-end;

        @media (min-width: 1200px) {
            width: 198px;

            .is-home-sector & {
                width: 155px;
            }
        }

        @media (max-width: 992px) {
            padding-right: 0;
        }
    }

    &__search {
        width: 90px;
        height: 43px;
        line-height: 43px;
        border-bottom: 2px solid $secondary-color;
        cursor: pointer;
        position: relative;

        @media (min-width: 992px) {
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
                content: '';
                width: 0;
                height: 43px;
                padding: 0;
                border-bottom: 2px solid $primary-color;

                @include transition(width 0.25s);
            }
        }

        @media (max-width: 992px) {
            border: none;
            width: auto;
            height: auto;
            line-height: inherit;
            margin-top: 0;
        }

        .fa {
            font-size: 16px;
            line-height: 48px;
            float: right;

            @include transition(font-size .25s ease-in-out);

            @media (max-width: 992px){
                float: none;
                font-size: 18px;
                line-height: 75px;
            }
        }
    }
}

// Logo 70 - temporale
.sector--corporate.logo--70 .site-head__logo img {
    width: 190px;
    
    @media (min-width: 992px){
        width: 275px;
    }
}
.sector--wood.logo--70 .site-head__logo img {
    width: 175px;

    @media (min-width: 992px){
        width: 245px;
    }
}
// --

@media (min-width: 992px) {
    .site-head__search:hover {
        &:before {
            width: 100%;
        }
    }
}

// Superfici
body.superfici .site-head__logo img{
    zoom: 1.1;
    -ms-zoom: 1.1;
    -webkit-zoom: 1.1;
    -moz-transform:  scale(1.1,1.1);
    -moz-transform-origin: left center;
}


.site-en_US {
    &.a-home-network-sites,
    &.a-content-network-sites,
    &.c-news.is-home-sector,
    &.c-product.is-home-sector,
    &.cmswood,
    &.cmsstone,
    &.cmsmetal,
    &.cmsglass,
    &.cmsplastic,
    &.cmsadvancedmaterials {
        .cms-phone-number {
            margin-left: 15px;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 14px;
            display: inline-block;
        }
    }
}

