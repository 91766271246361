.modal {
    &--download {
        width: 100%;
        height: 100vh;
        margin: 0;
        background: white;
        overflow: hidden;

        .modal-dialog {
            width: 100%;
            height: 100%;
            margin: 0;

            opacity: 0;
        }

        &.in .modal-dialog {
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;

            opacity: 1;
        }

        &.fade .modal-dialog {
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            -webkit-transition: opacity 0.3s ease-out;
            -moz-transition: opacity 0.3s ease-out;
            -o-transition: opacity 0.3s ease-out;
            transition: opacity 0.3s ease-out;
        }

        .modal-content {
            width: 100vw;
            height: 100vh;
            position: relative;
            border: none!important;
            box-shadow: none!important;
            border-radius: 0;
            overflow: hidden;

            // posiziono al centro contenuto form
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            // icona chiusura
            .close-icon {
                position: absolute;
                top: 1.7em;
                right: 1.7em;
                cursor: pointer;
                width: 40px;
                height: 40px;
                border: none;
                background: none;

                &:after,
                &:before {
                    background-color: #ccc;
                }
            }

            &:after,
            &:before {
                content: '';
                position: absolute;
                pointer-events: none;

                @supports (-webkit-appearance:none) {
                    width: calc(100% + 15px);
                    height: calc(100% + 15px);
                    border: 1.5em solid rgba($secondary-color,.85);
                }

                @include transform(translate3d(0, 0, 0));

                @media (max-width: 1024px){
                    display: none;
                }
            }
        }

        // Body con form esterno centrato nella modale
        .modal-body {
            max-width: 1140px;
            margin: auto;
            padding: 0 15px;
        }
    }
}

// Form esterno container 
.cd-form-container{
    #clickdimensionsForm {
        .clickdform input[type=email],
        .clickdform input[type=text], 
        .clickdform textarea, 
        .clickdform select {
            font-family: "Helvetica Neue","Helvetica",Arial,sans-serif!important;
            font-weight: 300;
            padding: .5em .5em 0.25em!important;
            width: 100%;
            background: #f7f7f7;
            color: #333;
            margin-bottom: 10px;
            border: none;
            height: 38px;
            border-bottom: 2px solid #ccc;

            -webkit-appearance: none;
            -webkit-transition: border-color 0.25s ease-in-out;
            -o-transition: border-color 0.25s ease-in-out;
            transition: border-color 0.25s ease-in-out;
        }

        .clickdform .requiredStar {
            color: black;
        }

        input[type=checkbox] {
            margin-right: 5px!important;
        }

        .clickdform .maxSize3 {
            max-width: inherit!important;
            font-family: "Helvetica Neue","Helvetica",Arial,sans-serif!important;
            font-weight: 300;
        }
        .responsiveCellSize3,
        .clickdform .minSize3 {
            width: auto!important;
            max-width: inherit!important;
        }
    }
}