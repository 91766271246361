
.lang-selector {
    min-width: 180px;
    width: auto;
    float: right;
    margin-top: -5px;

    @media (max-width: 767px){
        margin-top: 0;
        float: none;
        width: 180px;
        margin: auto;

        img {
            float: left;
        }
    }

    &__lbl {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width: 768px){
        &:hover {
            cursor: pointer;

            .lang-selector__lbl {
                border-bottom-color: $grey-foot;
            }

            .fa {
                color: $grey-foot;
            }
        }
    }

    &__lbl {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
        color: $grey-foot;
        font-weight: 400;
        float: right;
        width: 100%;
        border-bottom: 2px solid $grey-border;

        @include transition(border .25s ease-in-out 0s);

        > span {
            text-align: right;
            float: right;
        }
    }

    .fa {
        color: $grey-border;
        text-align: left;
        float: left;
        font-size: 20px;
        margin-top: -3px;

        @include transition(color .25s ease-in-out 0s);
    }
}

.nav-lang {
    width: 100%;

    @media (max-width: 767px) {
        padding: 0 15px;
    }

    &__title {
        margin-bottom: 40px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        display: inline-block;
        width: 100%;
    }

    &__item {
        padding: 0;
        list-style: none;
        margin: 10px 0;
        min-height: 90px;

        @media (max-width: 767px) {
            margin: 8px 0;
            min-height: inherit;
        }
    }

    &__link {
        @extend .block;

        &:hover {
            text-decoration: none;
        }

        &:hover figcaption {
            text-decoration: none;
            //opacity: .7;
        }
    }

    .flag {
        width: 180px;
        height: auto;
        margin: auto;
        vertical-align: middle;
        text-align: center;
        border: 2px solid transparent;
        border-radius: 15px;
        padding: 15px 0;

        @include transition(border .2s ease-in-out 0s);

        &:hover {
            border-color: $secondary-color;
        }

        @media (max-width: 767px){
            width: auto;
        }

        @media (min-width: 768px){
            img {
                box-shadow: 0 2px 10px rgba(0,0,0,.2);
            }
        }

        figcaption {
            font-size: 22px;
            font-weight: 300;
            vertical-align: middle;
            width: auto;
            padding: 0 10px;
            margin: auto;
            margin-top: 8px;

            @media (max-width: 767px) {
                font-size: 18px;
                margin-top: 5px;
            }
        }
    }
}

// Content languages
.site-content--languages {
    margin: auto;
}
.site-content--languages .page-title {
    margin-bottom: 50px;
}

// Flex adding
.row-fluid--flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.list-lang {
    padding-bottom: 72px;
    width: 100%;

    @extend .clearfix;

    &__title {
        font-size: 24px;
        padding-bottom: 12px;
        border-bottom: 2px solid $secondary-color;
    }

    &__ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__ul li {
        float: left;
        width: 16.63%;
        height: 30px;
        margin: 18px 0;
        padding-right: 20px;

        @media (max-width: 1200px){
            width: 20%;
        }

        @media (max-width: 767px){
            width: 50%;
        }

        @media (max-width: 360px){
            width: 100%;
        }
    }

    &__link {
        display: block;
        color: inherit;
        cursor: pointer;
        text-decoration: none;

        &:hover span {
            text-decoration: underline;
        }
    }

    .flag-icon {
        margin-right: 12px;
        display: inline-block;
        float: left;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        box-shadow: 0 0 0 1px rgba(0,0,0,.2);

        img {
            width: 30px;
            height: 30px;

            display: none;
        }
    }

    &__country {
        max-width: 70%;
        display: inline-block;
        vertical-align: middle;
        color: $default;
        //font-size: 12px;
        line-height: 1.2;

        @media (max-width: 767px){
            max-width: 65%;
        }

        @media (max-width: 360px){
            max-width: inherit;
            width: auto;
        }
    }

    // Use grid css no float
    &__ul--grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 3rem;

        //2 colonne grid
        &.grid-2col {
            grid-template-columns: repeat(2, 1fr);
        }

        > li {
            float: none;
            width: auto;
            height: auto;
            margin: 0;
            padding: 0;
            text-align: center;
        }

        .list-lang__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }

        .flag-icon {
            float: none;
            width: 50px;
            height: 50px;
            margin-right: 0px;
        }
        .flag-icon:before {
            display: none;
        }
    } 
}
