@import "helpers/variables";

// Base
@import "base/reset";
@import "base/typography-fast";
@import "base/typography";

// Helpers (Variables, Functions, Mixins, ecc...)
@import "helpers/bourbon/bourbon";
@import "helpers/mixins";
@import "helpers/helpers";
@import "helpers/layout";

// Vendors (Bootstrap, Font Awesome, ecc..)
@import "vendors/bootstrap";
@import "vendors/flags/flag-icon";
@import "vendors/font-awesome";
//@import "vendors/fancybox-v2";

// Partials
@import "partials/links";
@import "partials/buttons";
@import "partials/headings";
@import "partials/slider";
@import "partials/forms";
@import "partials/ajax-form";
@import "partials/alerts";
@import "partials/awesomeplete";
@import "partials/tables";
@import "partials/primary-nav";
@import "partials/secondary-nav";
@import "partials/sub-nav";
@import "partials/nav-prod";
@import "partials/lang-selector";
@import "partials/filters";
//@import "partials/languages";
//@import "partials/breadcrumbs";
@import "partials/spots";
@import "partials/card";
@import "partials/maps";
@import "partials/fancy";
//@import "partials/lists";
//@import "partials/snippet";
//@import "partials/hotspot";
@import "partials/icons";
@import "partials/win-fullscreen"; 
@import "partials/bg-video";
@import "partials/cookie";
@import "partials/bricks";
@import "partials/loading";
//@import "partials/mobile-nav";
@import "partials/alternative-mobile-menu";
@import "partials/tax-deduction-form";
@import "partials/tags";
@import "partials/faq";
@import "partials/product-list";
@import "partials/cms-case-studies-table";
@import "partials/collapse";
@import "partials/slick-carousel";
@import "partials/sliderSwiper";
@import "partials/hero";
@import "partials/modal-download";
@import "partials/mosaico";
@import "partials/slider__contimg";
@import "partials/scroll-to-top";
@import "partials/multi-action-widget";
@import "partials/video-html5";
@import "partials/dropdown-columns";
@import "partials/flex-items";

// Layout
@import "layout/header";
@import "layout/content";
@import "layout/footer";
@import "layout/sidebar";
@import "layout/pimcore";

// Pages
@import "pages/home";
@import "pages/home-sector";
@import "pages/pagina-content";
@import "pages/pagina-content-list";
@import "pages/piazza-prodotti";
@import "pages/scheda-prodotto";
@import "pages/landing";
@import "pages/articolo";
@import "pages/used-machinery";
@import "pages/case-histories";
@import "pages/press-release";
@import "pages/search";
@import "pages/garanzia";
@import "pages/news";
@import "pages/cms-piazza";

@import "vendors/slick/slick";
@import "vendors/fancybox";
@import "vendors/swiper/swiper";
@import "vendors/tippy-tooltip";

@import "templates/ru";
@import "templates/landing";

@import "helpers/custom";

