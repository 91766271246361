
.pimcore_area_business-card-map {
    margin-bottom: 30px;
}

.pimcore_tag_checkbox {
    padding: 0!important;
    float: left;
    margin-right: 10px;

    hr {
        clear: left;
    }
}

.pimcore_tag_select {
    padding: 0!important;
}

.pimcore_area_Extera3Columns {
    padding: 15px 0; 
}

.pimcore_area_gallery-folder,
.pimcore_area_video {
    padding: 30px 0;
}

.area-gallery-carousel {
    background-color: $grey-light;

    .carousel-control .glyphicon:before {
        content: '';
        font-family: fontAwesome;
        text-rendering: optimizeLegibility;
        font-size: 50px;
        color: $default;
    }

    .carousel-control .glyphicon-chevron-left:before {
        content: '\f053';
    }

    .carousel-control .glyphicon-chevron-right:before {
        content: '\f054';
    }

    .carousel-indicators li {
        border-color: $default;

        &.active {
            background-color: $default;
        }
    }
}

.pimcore_area_wysiwyg {
    h1,h2,h3,h4,p {
        word-break: break-word;
    }

    @media (max-width: 768px){
        overflow-x: scroll; 
    }
}

.pimcore_tag_link .pimcore_edit_link_button{
    z-index: 1000;
    left: 0px;
    right: auto !important;
}

.pimcore_area_spot-img-grigio-secondary,
.pimcore_area_spot-enfasy {
    .pimcore_area_edit_button {
        z-index: 100;
    }
}

.editmode {
    &__cont {
        display: flex;
        align-items: center;
        margin: 30px -15px;

        > span {
            display: flex;
            align-items: center;
            margin: 0 15px;
            display: inline-block;        
        }

        .pimcore_editable {
            overflow: unset!important;

            &.pimcore_tag_input {
                padding: 5px 10px;
                //background-color: white;
            }
        }
    }  
}

// Fix dimensione componente errata editmode
.pimcore_area_image .pimcore_tag_image {
    width: auto!important;
    height: auto!important;
}

.pimcore_area_home-news {
    padding-top: 30px;
}