.card {
    min-height: 200px;
    padding: 15px;
    background-color: $grey-light;
    //margin-bottom: 20px;

    @media (max-width: 767px){
        min-height: inherit;
        height: auto;
    }

    > h3 {
        //font-size: 20px;
        display: block;
    }

    &--business {
        padding: 15px;
        min-height: 275px;
        margin-bottom: 30px;

        @media (max-width: 767px){
            min-height: inherit;
            height: auto;
        }
    }

    &--border {
        -webkit-box-shadow:inset 0px 0px 0px 2px $primary-color;
        -moz-box-shadow:inset 0px 0px 0px 2px $primary-color;
        box-shadow:inset 0px 0px 0px 2px $primary-color;
    }

    &__logo {
        display: inline-block;
        margin-bottom: 20px;
    }

    &__info {
        padding: 0 5px;
    }

    &__business {
        font-weight: bold;
    }

    .country {
        font-weight: bold;
    }
}

// List js

#list-js {
    .map__finder {
        float: left;
        min-width: 300px;
        margin-bottom: 30px;
    }
    .map__finder input {
        width: 100%;
    }

    .list {
        clear: left;
        //display: inline-block;
        margin-top: 30px;
    }
}
