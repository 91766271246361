
.ajax-form {
    position: relative;
    margin-top: 15px;

    .alert {
        display: none;
        margin-top: 30px;
    }

    &.form--done,
    &.form--success {
        .alert-success {
            display: block;
        }
    }

    &.form--error {
        .alert-danger {
            display: block;
        }
    }

}
