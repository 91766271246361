
.slider {
    position: relative;

    .slider__cont,
    .slider__contimg {
        min-height: 43vh;
        background-size: cover;
        background-position: center;

        &:hover{
            .slick-arrow{
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }

    &__caption {
        padding: 40px 60px;
        
        @media (min-width: 1024px){
            max-width: 60%;
        }
        &.align-left{
            float: none;
        }
        &.align-center{
            margin: 0 auto;
        }
        &.align-right
        {
            float: right;
            &:after{
                content: "";
                clear: both;
            }
        }
    }

    .slick-track:focus {
        outline: 0;
    }

    .slick-arrow {
        position: absolute;
        background: none;
        border: none;
        top: 50%;
        opacity: .2;
        font: normal normal normal 16px / 1.4 FontAwesome;
        z-index: 1000;

        &:hover {
            opacity: 1;
        }

        &:before {
            display: inline-block;
            color: $white;
            text-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
        }
    }

    .slick-prev {
        left: 2%;

        &:before {
            content: '\f053';
        }
    }
    .slick-next {
        right: 2%;

        &:before {
            content: '\f054';
        }
    }

    .par {
        color: $white;
    } 
}
