
input[type=text],
input[type=email],
input[type=password],
input[type=search],
select,
textarea {
    border: none;
    height: 38px;
    border-bottom: 2px solid $secondary-color;
    border-radius: 0;
    background-color: transparent;

    -webkit-appearance: none;

    @include transition(border-color .25s ease-in-out);

    &:focus {
        outline: 0;
        border-color: $primary-color;
    }
}

textarea {
    height: 80px;
}


// select style
.style-select {
    position: relative;
    background-color: $grey-bg;

    &::before {
        content: '\f078';
        font-family: fontAwesome;
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 10px;
        color: $secondary-color;
        z-index: 0;
    }

    select {
        position: relative;
        z-index: 1;

        -webkit-appearance: none;
        appearance: none;

        //Firefox
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
}

.input-full {
    width: 100%;
}

// Form product
.form-wrap {
    max-width: 1140px;
    margin: auto;
    padding: 0 15px;

    @media (max-height: 740px){
        margin: 40px 0;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

}

// Form con animazione label
.new-form .input {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
	padding-top: 1em;

    .control-label {
        position: absolute;
    	top: 1.5em;
    	//font-size: 0.85em;
    	left: 25px;
    	display: block;
    	width: 100%;
    	text-align: left;
    	padding: 0em;
    	pointer-events: none;
    	-webkit-transform-origin: 0 0;
    	transform-origin: 0 0;
        z-index: 2;

    	-webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
    	transition: transform 0.2s 0.15s, color 1s;
    	-webkit-transition-timing-function: ease-out;
    	transition-timing-function: ease-out;

        &.required:after {
            color: $primary-color;
            content: ' *';
            display: inline;
        }
    }

    .form-control {
        padding: .5em .5em 0.25em;
    	width: 100%;
    	background: $grey-bg;
    	color: $default;
    	//font-size: 1.25em;
    }
    select.form-control {
        background-color: transparent;
    }

    .form-control:focus + .control-label,
    &.input--filled .control-label {
    	color: $primary-color;
    	-webkit-transform: translate3d(0, -1.6em, 0) scale3d(0.85, 0.85, 1);
    	transform: translate3d(0, -1.6em, 0) scale3d(0.85, 0.85, 1);

        //left: 15px;
    }

    .contact-interest option:first-child {
        visibility: hidden;
    }

    .info-partytype option:first-child {
        visibility: hidden;
    }
}

// Form principale - da unificare
// Form comuni già presenti
.form-horizontal {
    .head-form {
        margin-bottom: 20px;
    }

    .control-label {
        padding-bottom: 5px;
        display: inline-block;

        &.required:after {
            color: $primary-color;
            content: ' *';
            display: inline;
        }
    }

    .form-control {
        padding: .5em .5em 0.25em;
    	width: 100%;
    	background: $grey-bg;
        color: $default;
    	//font-size: 1.25em;
        margin-bottom: 10px;
    }

    .multiselect-container.dropdown-menu {
        display: block;
        position: static;
        float: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        li > a {
            outline: 0;
            padding: 3px 15px;

            &:focus,
            &:hover {
                outline: 0;
            }

            .checkbox {
                padding-top: 0;
            }

            input[type=checkbox] {
                position: static;
                margin-left: 0;
                margin-top: 0;
                margin-right: 5px;
            }
        }
    }

    select[multiple] {
        height: 163px;

        option {
            padding: .25em .5em;
        }
    }

    .contact-acceptance.add-margin {
        margin-top: 30px;
    }

    .contact-acceptance.checkbox {
        label {
            display: none;
        }

        input[type=checkbox] {
            margin-left: 0;
            width: auto;
        }

        .hint {
            padding-left: 25px;
        }

        &.has-error .hint {
            color: $state-danger-text;
        }

        /*.hint small {
            font-size: 100%!important;
        }*/
    }

    .contact-acceptance {
        &.has-error input[type=checkbox] {
            box-shadow: 0 1px 10px $state-danger-text;
        }
    }

    .btn {
        //margin-top: -50px;
        float: right;
        @extend .btn--rounded;

        @media (max-width: 767px){
            margin-top: 15px;
        }
    }

    .field-with-description {
        position: relative;

        .hint {
            position: absolute;
            bottom: -30px;
            left: 15px;
        }
    }

    // Error
    .form-group {
        &.has-error{
            .alert {
                display: none;
            }

            .hint {
                color: $state-danger-text;
            }

            input[type=checkbox] {
                box-shadow: 0 1px 4px $state-danger-text;
            }
        }
    }
    .has-success .required:after {
        color: $state-success-text;
    }
    .has-error .required:after {
        color: $state-danger-text;
    }
    .has-success .form-control,
    .has-error .form-control {
        box-shadow: 0 0 0 white;
    }

}

// Brick form - gdpr form
.pimcore_area_ExteraForm, .pimcore_area_CDForm {
    margin: 0 -15px;
}

.gdpr-form,
.pimcore_area_ExteraForm, .pimcore_area_CDForm form {
    @extend .form-horizontal;
    background-color: transparent!important;

    .form-group {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    textarea {
        height: 80px!important;
    }

    .contact-acceptance.checkbox {
        label {
            display: none;
        }

        input[type=checkbox] {
            margin-left: 0;
        }

        .hint {
            padding-left: 25px;
        }
    }

    .style-select:before {
        z-index: 2;
    }

    .control-label {
        text-align: left;
        display: inline-block;
        margin-bottom: 10px;
    }

    input[type=checkbox].form-control {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    // gdpr item
    .gdpr-item-newsletter {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        input[type=checkbox] {
            margin-top: 0px;
            margin-right: 10px;
        }
    }
    .gdpr-item {
        margin-bottom: 20px;

        .form-fullscreen & {
            @media (min-width: 992px){
                font-size: 12px;
            }
        }

        .control-label {
            margin-left: 5px;
            margin-bottom: 0px;
            padding: 0;
            font-size: 14px;
            display: inline-block;

            .form-fullscreen & {
                @media (min-width: 992px){
                    font-size: inherit;
                }
            }
        }

        input[type=checkbox] {
            margin-top: 0px;
            position: relative;
            top: 1px;
        }

        // extera text modal link
        [data-extera-gdpr] {
            display: inline-block;
            margin-bottom: 1.5rem;
        }

        p.hint {
            margin-bottom: 5px;
        }
    }

    // con gdpr
    .btn {
        float: left!important;
    }
}

// Form ricerca
.form-search {
    .form-control {
        height: 40px;
        padding: .5em;
    }
}

fieldset {
    clear: left;
}

fieldset[data-class*="form-group"] {
    margin: 0 15px !important;

    /*.form-group{
        margin-left: -15px !important;
        margin-right: -15px !important;
    }*/
}

.form-horizontal input[type="radio"],
.pimcore_area_ExteraForm, .pimcore_area_CDForm input[type="radio"].form-control {
    display: inline-block!important;
    width: auto!important;
    margin: 0!important;
    padding: 0!important;
    margin-right: 5px!important;
    margin-bottom: 10px!important;
}

// Gdpr form
.gdpr-form {
    .gdpr-item {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        padding-top: 0;
        margin-bottom: 20px;

        .form-fullscreen & {
            margin-bottom: 1rem;
        }

        .control-label {
            font-weight: bold;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 15px;
        }

        input[type=checkbox].form-control {
            width: auto!important;
            margin: 0!important;
            position: absolute;
            top: auto;
            bottom: 6px;
            left: 15px;
        }
    }

    .btn {
        float: left;
    }

}

// disclaimer form gdpr compliant
.form-disclaimer {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: bold;

    .jcf-checkbox,
    input[type=checkbox] {
        display: none;
    }
}

// Click dimension form download
//.clickdform #clickdimensionsForm {
//    input[type=text],
//    input[type=email],
//    input[type=password],
//    input[type=search],
//    select,
//    textarea {
//        font-size: 16px!important;
//        font-family: "Helvetica Neue LT W01_41488878","Helvetica", Arial, sans-serif!important;
//        border: none!important;
//        height: 38px!important;
//        border-bottom: 2px solid $secondary-color!important;
//        border-radius: 0!important;
//        background-color: transparent!important;
//        -webkit-appearance: none!important;
//        @include transition(border-color .25s ease-in-out);
//
//        &:focus {
//            outline: 0!important;
//            border-color: $primary-color!important;
//        }
//    }
//}



