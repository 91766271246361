:root {
    --fastDuration: .25s;
    --ultraFastDuration: .15s;
    --bezier: cubic-bezier(0.54, -0.24, 0.46, 1.28);
    --bgdefault: #f0f0f0;
    --activeButtonPlayer: 169, 210, 255;
}

.flexy {
    &__item {
        display: flex;
        flex-wrap: nowrap;
    }

    &__child {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        &--fill {
            flex-basis: 100%;
        }
    }
    &--row {
        flex-direction: row;
    }
    &--column {
        flex-direction: column;
    }
    &--between {
        justify-content: space-between;
    }
    &--around {
        justify-content: space-around;
    }
    &--justify-start {
        justify-content: flex-start;
    }
    &--justify-end {
        justify-content: flex-end;
    }
    &--justify-center {
        justify-content: center;
    }
    &--items-center {
        align-items: center;
    }
    &--self-end
    {
        align-self: flex-end
    }
    &--reverse-column {
        flex-direction: column-reverse;
    }
    &--reverse-row {
        flex-direction: row-reverse;
    }
}

.svg-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.svg-icon {
    width: 16px;
    height: auto;
    transform-origin: 50% 50%;
    //stroke-width: 3;
    fill: white;

    &--rotated-180 {
        transform: rotate(180deg);
    }

    &--big {
        width: 35px;
    }
}

.video-hero-html5 {
    position: relative;
    height: auto;
    aspect-ratio: 4 / 3;
    //min-height: 400px;
    background-color: #f0f0f0;
    overflow: hidden;

    @media (min-width: 800px) {
        max-height: 85vh;
        width: 100%;
        aspect-ratio: 16 / 9;
    }

    &__action {
        position: absolute;
        bottom: 12%;

        // style
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(255,255,255,.7)!important;
        border-radius: 50%;
        padding: 20px;
        padding-left: 23px;
        transition: border-color .25s ease-in-out;

        svg {
            opacity: .7;
            transition: opacity .25s ease-in-out;
        }

        &:hover {
            border-color: rgba(255,255,255,1)!important;

            svg {
                opacity: 1;
            }
        }
    }

    picture {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)!important;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        aspect-ratio: 4/3;

        @media (min-width: 800px) {
            aspect-ratio: 16/9;
        }
    }

    .section__article {
        width: 100%;
    }

    // Dimensione da valutare
    .video {
        width: 90%;
        position: relative;
        overflow: hidden;
        font-size: 1.2em;
        box-shadow: 0 2.5em 2.5em -1.875em rgba(0, 0, 0, .5),
        0 1.25em 5em 1em rgba(0, 0, 0, .2);

        // first view
        visibility: hidden;
        opacity: 0;
        transition: all .25s ease-in-out;

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }

        // first view
        visibility: hidden;
        opacity: 0;
        transition: all .25s ease-in-out;

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }

        @media (pointer: fine){
            &:hover .video__control {
                opacity: 1;
                transform: translate(0, 0);
            }
        }

        @media (min-width: 768px) {
            width: 40em;
        }

        &__player {
            width: 100%;
            height: 100%;
            display: inline-block;
        }

        &__control {
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 2;
            position: absolute;
            box-sizing: border-box;
            padding: .5em;
            background-color: rgba($black, .5);
            transition: all var(--fastDuration) ease-in-out;

            opacity: 1;
            transform: translate(0, 0);

            @media (pointer: fine) {
                padding: 1em;
                opacity: 0;
                transform: translate(0, 100%);
            }

            ul {
                padding-left: 0px;
                margin-bottom: 0px;
                align-items: center;
                justify-content: space-between;
            }

            &__button {
                padding: 0 .5em;
                display: flex;
                cursor: pointer;
                position: relative;
                transition: all var(--fastDuration) ease-in-out;

                &.is-active {
                    svg {
                        fill: rgba(var(--activeButtonPlayer), 1);
                    }
                }

                &:before {
                    content: '';
                    display: inline-block;
                    width: 100%;
                    height: 1.9em;
                    z-index: -1;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    border-radius: 1.9em;
                    background-color: var(--bgDefault);
                    transition: all var(--ultraFastDuration) var(--bezier);
                }

                &.volume {
                    //flex-basis: 2em;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        width: auto;
                        height: 25px;
                    }

                    &[data-active=false] {
                        .svg-mute {
                            display: inline-block;
                        }
                        .svg-unmute {
                            display: none;
                        }
                    }
                    &[data-active=true] {
                        .svg-mute {
                            display: none;
                        }
                        .svg-unmute {
                            display: inline-block;
                        }
                    }
                }

                @media (pointer: fine) {
                    &:hover,
                    &.active {
                        &:before {
                            transform: translate(-50%, -50%) scale(1);
                        }

                        > .video__track__range__sound {
                            padding-left: .5em;
                            pointer-events: all;
                            opacity: 1;
                            flex: 1;
                        }
                    }
                }

                @media (pointer: coarse){
                    &:before {
                        transform: translate(-50%, -50%) scale(1);
                    }

                    > .video__track__range__sound {
                        padding-left: .5em;
                        pointer-events: all;
                        opacity: 1;
                        flex: 1;
                    }
                }

            }

            > .video__control__button.volume {

                /*@media (-webkit-min-device-pixel-ratio: 2) {
                    display: none;
                }*/

                @media (pointer: fine){
                    flex-basis: inherit;

                    &:hover {
                        flex-basis: 6em;
                    }
                }

            }
        }

        &__duration {
            padding-right: .5em;
            font-size: 14px;
            color: white;
        }

        &__track {
            &__range {
                &[type=range] {
                    -webkit-appearance: inherit; /* WebKit */
                    width: 100%;
                    background-color: transparent;
                }
                &[type=range]:focus {
                    outline: none;
                }
                &[type=range]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 5px;
                    box-sizing: border-box;
                    cursor: pointer;
                    box-shadow: none;
                    background: transparent;
                    border-radius: 2px;
                    border: 1px solid $white;

                    @media (min-width: 768px) {
                        height: 1.5em;
                        border: 2px solid $white;
                        border-radius: 1.5em;
                    }
                }
                &[type=range]::-webkit-slider-thumb {
                    box-shadow: none;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid $white;
                    background: white;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -2px;

                    @media (min-width: 768px) {
                        height: 1.5em;
                        width: 1.5em;
                    }
                }
                &[type=range]:focus::-webkit-slider-runnable-track {
                    // background: var(--bgDefault);
                }
                &[type=range]::-moz-range-track {
                    width: 100%;
                    height: 1.5em;
                    box-sizing: border-box;
                    cursor: pointer;
                    box-shadow: none;
                    background: transparent;
                    border-radius: 1.5em;
                    border: 2px solid $white;
                }
                &[type=range]:focus::-moz-range-track {
                    // background: var(--bgDefault);
                }
                &[type=range]::-moz-range-thumb {
                    box-shadow: none;
                    border: 0;
                    height: 1.5em;
                    width: 1.5em;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid $white;
                    background: transparent;
                    cursor: pointer;
                }
                &[type=range]::-ms-track {
                    width: 100%;
                    height: 1.5em;
                    box-sizing: border-box;
                    cursor: pointer;
                    background: transparent;
                    border-color: transparent;
                    color: transparent;
                    border: 2px solid $white;
                }
                &[type=range]::-ms-fill-lower {
                    background: transparent;
                    border: 0;
                    border-radius: 1.5em;
                    box-shadow: none;
                }
                &[type=range]::-ms-fill-upper {
                    background: transparent;
                    border: 0;
                    border-radius: 1.5em;
                    box-shadow: none;
                }
                &[type=range]::-ms-thumb {
                    box-shadow: none;
                    border: 0;
                    height: 1.5em;
                    width: 1.5em;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid $white;
                    background: transparent;
                    cursor: pointer;
                }
                &[type=range]:focus::-ms-fill-lower {
                    background: transparent;
                }
                &[type=range]:focus::-ms-fill-upper {
                    background: transparent;
                }
                &__sound {
                    display: flex;
                    width: 32px;
                    opacity: 0;
                    padding-left: 0;
                    pointer-events: none;
                    transition: all var(--ultraFastDuration) var(--bezier);

                    @media (min-width: 768px) and (pointer: coarse){
                        width: 64px;
                    }

                    @media (hover: hover){
                        width: 0px;
                    }
                }
            }
        }
    }

    .v-tooltip {
        position: relative;

        &:after {
            content: attr(data-tooltip-content);
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 1em;
            font-size: 12px;
            color: $white;
            background-color: var(--bgDefault);
            opacity: 0;
            transition: all var(--fastDuration) var(--bezier);

            display: none;

            @media (hover: hover){
                display: inline-block;
            }
        }
        &:hover:after {
            opacity: 1;
        }
    }
}

.video__control {
    @media (max-width: 768px){
        justify-content: space-between;
    }
}

.video__track {
    position: absolute!important;

    @media (min-width: 768px){
        position: relative!important;
    }
}

.video__track {
    @media (max-width: 768px){
        top: 0;
        left: 0;
        width: 100%;
    }
}

.video__track__range__sound {
    @media (max-width: 768px){
        width: 32px;
    }
}