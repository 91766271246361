
a {
    color: $primary-color;
    @include transition(all .25s ease-in-out);

    .site-content__text & {
        font-weight: bold;
    }

    .site-sidebar & {
        color: $default;
    }

    .site-footer & {
        color: $grey-foot;
        white-space: nowrap;
    }
}

.external-link {
    margin-left: 10px;

    &:after {
        content: '\f08e';
        font-family: fontAwesome;
        display: inline-block;
        font-size: 12px;
    }
}

.fa-external-link {
    font-size: 12px!important;
}
