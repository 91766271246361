.multi-action-widget {
    position: fixed;
    top: 35vh;
    right: 1rem;
    z-index: 999;
    //padding: 1.5rem 1rem;
    //background-color: white;
    //border: 1px solid rgba(0,8,16,0.15);
    //border-radius: 10px;

    transition: opacity .25s linear;

    .js-scroll-up &,
    .is-scrolled & {
        opacity: 1;
        //visibility: visible;
    }

    .js-scroll-down & {
        opacity: 0;
        //visibility: hidden;
    }

    nav {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;

        > span {
            cursor: pointer;
        }
    }

    svg {
        fill: white;
        max-width: 25px;
    }

    nav > span,
    [data-template] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: #6a7b8f;
        box-shadow: 0 3px 14px -0.5px rgba(0,8,16,0.08);
        border-radius: 50%;
        transition: background-color .25s linear;

        &:hover {
            background-color: $primary-color;
        }
    }
}

.widget-box {
    font-size: 18px;
    padding: 3rem;
}

.share-list {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 1rem 1.2rem;

    > * {
        cursor: pointer;
        opacity: .8;
        transition: opacity .25s ease;

        &:hover {
            opacity: 1;
        }
    }

    svg {
        max-width: 24px;
        fill: $primary-color;
    }
}

.form-fullscreen.blocked {
    display: none;
}