
.obj-event-date,
.obj-event-place {
    display: inline-block;
    margin-bottom: 30px;
}

.obj-event-date {
    color: $grey-foot;
    .fa {
        margin-right: 10px;
    }
}

.obj-event-place {
    margin-left: 20px;
}

body.c-news,
body.news {
    .date {
        display: inline-block;
        margin-bottom: 30px;
        color: $grey-foot;
    }
    .date .fa {
        margin-right: 10px;
    }
}
