.close-icon {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;

    /*&:hover {
        &::before,
        &::after {
          background: $blue;
        }
    }*/

    &::before, &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #000;
    }

    &::before {
        @include transform(rotate(45deg));
    }
    &::after {
        @include transform(rotate(-45deg));
    }
    &.big {
        @include transform(scale(3));
    }
    &.hairline {
        &::before, &::after {
          height: 1px;
        }
    }
    &.thick {
        &::before, &::after {
          height: 4px;
          margin-top: -2px;
        }
    }
    &.black {
        &::before, &::after {
          height: 8px;
          margin-top: -4px;
        }
    }
    &.heavy {
        &::before, &::after {
          height: 12px;
          margin-top: -6px;
        }
    }
    &.pointy {
        &::Before, &::after {
          width: 200%;
          left: -50%;
        }
    }
    &.rounded {
        &::before, &::after {
          border-radius: 5px;
        }
    }
    &.blades {
        &::before, &::after {
          border-radius: 5px 0;
        }
    }
    &.warp {
        &::before, &::after {
          border-radius: 120% 0;
        }
    }
    &.fat {
        &::before, &::after {
          border-radius: 100%;
        }
    }
}
