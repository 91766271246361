$brick-margin: 1.5em 0;

.pimcore_area_image {
    figure {
        display: table;
        table-layout: fixed;
        margin-bottom: 1em;
        width: 100%;

        //Firefox
        @-moz-document url-prefix() {
            width: 100%;
        }

        img {
            border: 0;
            width: 100%;
        }

        a{
            display: block;
        }

        figcaption {
            display: table-caption;
            caption-side: bottom;
            font-style: italic;
            font-size: 12px;
        }
    }
}

.pimcore_area_gallery-carousel {
    margin: $brick-margin;

    .carousel-inner {
        background-color: $grey-light;
    }
}

.pimcore_area_gallery-single-images {
    margin: $brick-margin;

    .thumbnail:hover {
        border-color: $default;
    }
}

// Brick carousel
.area-gallery-carousel {
    .site-content & {
        margin: 30px 0;
    }

    .item {
        text-align: center;
    }

    .item > img {
        margin: auto;
    }

    .carousel-control {
        width: 10%;
    }
}

//Panel

.panel .list-group-item > a {
    word-break: break-word;
}
