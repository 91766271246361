
.js-for-stick {

    .product-content & {
        padding: 0;
        background-color: $white;
        z-index: 20;
    }

    &.is_stuck {

    }
}

.row-fluid .row {
    @media (max-width: 767px){
        margin: 0 -15px;
    }
}
.row-fluid .row .row {
    @media (max-width: 767px){
        margin: 0 -15px;
    }
}
