
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    .pimcore_area_wysiwyg & {
        margin-top: .35em;
        margin-bottom: .55em;
    }
}

.h1,.h2,.h3,.h4,.h5,.h6 {
    display: inline-block;
}

// Responsive mode
@media ( max-width: 1140px ){
    h1, .h1 { font-size: 4.0rem; }
    h2, .h2 { font-size: 3.6rem; }
    h3, .h3 { font-size: 3.0rem; }
    h4, .h4 { font-size: 2.4rem; }
    h5, .h5 { font-size: 2.0rem; }
    h6, .h6 { font-size: 1.8rem; }
}

@media ( max-width: 767px ){
    h1, .h1 { font-size: 3.2rem; }
    h2, .h2 { font-size: 2.8rem; }
    h3, .h3 { font-size: 2.4rem; }
    h4, .h4 { font-size: 2.0rem; }
}

.title {
    &--icon {
        padding-left: 70px;
        position: relative;
        margin: 0;

        .fa {
            position: absolute;
            top: 50%;
            left: 0;
            font-size: 30px;
            margin-top: -15px;
            color: $secondary-color;
        }
    }

    // Slider
    &--big {
        font-weight: 300;
        letter-spacing: -2px;
        font-size: 60px;
        text-shadow: 0 2px 7px rgba(0,0,0,.2);
        color: $white;

        @media (max-width: 767px){
            font-size: 4rem;
        }

    }

}

.page-title {
    margin-bottom: 25px;
}

/* Vecchi stili per wyziwyg */

.subtitle-highlight {
    padding: .5em;
    color: $white;
    background-color: $primary-color;
    margin-top: 20px;

    &.subtitle-invert {
        color: $primary-color;
        background-color: $grey-light;
    }
}
