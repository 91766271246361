
.product {
    &__title {
        margin-bottom: 10px;
        width: 100%;
    }

    &__subtitle {
        clear: left;
        margin-top: 0;
    }

    &__video,
    &__image {
        margin: 30px 0;
        text-align: center;
        aspect-ratio: 53/30;

        @supports not (aspect-ratio: 53/30) {
            .product__video::before,
            .product__image::before {
                float: left;
                padding-top: 56.60%;
                content: '';
            }
              
            .product__video::after,
            .product__image::after {
                display: block;
                content: '';
                clear: both;
            }
        }

        @media (max-width: 767px){
            margin: 15px 0;
        }

        figcaption {
            display: flex;
            justify-content: center;
            
            font-size: 80%;

            &:before,
            &:after {
                content: '"';
                display: inline-block;
            }
        }
    }        
}

.product-content {
    .spot-content--colored {
        margin-top: 25px;
    }
}

.product-info {
    padding-top: 80px;
    padding-bottom: 20px;

    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 10px;
    }

    ul {
        @media (max-width: 767px){
            padding-left: 18px;
        }
    }

    > .h5 {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 30px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .advantage .h5 {
        display: block;
    }

    &.no-padding {
        padding-top: 0;
    }

    &.last {
        padding-bottom: 0;
    }

    .table-responsive {
        //width: 69%;
        width: auto;

        /*@media (max-width: 767px){
            width: 99%;
        }*/
    }

    .table-responsive .table {
        margin-bottom: 0;
    }

    &.with-col .table-responsive {
        width: 99%;
    }

    .flat-table {
        width: 100%!important;
    }

    &.with-col .flat-table {
        width: 100%!important;
    }

    .spot-video{
        margin-bottom: 22px;
    }

    .thumbnail{
        margin-bottom: 22px;
        padding: 0;
        > img{
            width: 100%;
        }
    }

    .col-sm-4:nth-child(3n+1){
        clear: left;
    }
}

.text-impact {
    padding: 100px 0;
    margin: 0 auto;

    &:not(.text-impact--full){
        max-width: 75%;
    }

    @media (max-width: 767px){
        max-width: 100%;
        padding: 50px 0;
    }

    &.centered {
        text-align: center;
    }

    &__title {
        margin-top: 0;
    }

    &__text {
        line-height: 2;
        font-size: 16px;
    }

    &.custom-padding {
        padding-bottom: 50px;
    }
}

.advantage {
    &__col {
        margin-bottom: 10px;

        p,    
        strong {
            word-break: break-word;
        }

        @media (max-width: 767px){
            margin-bottom: 30px;
        }
    }

    &__image {
        display: table;
        //border: 1px solid $secondary-color;
        background-color: $white;
        margin-bottom: 20px;
        //padding: 0 30px;
        width: 100%;
        // height: 250px;
        // line-height: 250px;
        text-align: center;
        aspect-ratio: 105/79;

        @supports not (aspect-ratio: 105/79) {
            &::before {
                float: left;
                padding-top: 75.24%;
                content: '';
            }
              
            &::after {
                display: block;
                content: '';
                clear: both;
            }
        }

        @media (max-width: 767px){
            height: auto;
            line-height: inherit;
            margin-bottom: 15px;
        }

        img {
            width: auto;
            // max-height: 250px;
        }
    }

    p {
        line-height: 1.4;
        margin-bottom: 0;
    }

    &.with-col {
        line-height: 1.6;
    }
}

.request-info {
    margin-top: 40px;

    // cms buttons
    &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;

        @media screen and (max-width: 992px){
            flex-direction: column;
            gap: 2rem;

            > .btn {
                width: 100%;
                max-width: 320px;
                margin-top: 0;
            }
        }
    }

    input[type=email] {
        min-width: 240px;

        @media (max-width: 767px){
            text-align: center;
        }
    }

    .btn {
        @media (max-width: 767px) {
            margin-top: 10px;
            min-width: 240px;
            display: inline-block;
        }
    }
}

.cont-title {
    position: relative;

    > h1 {
        max-width: 80%;
    }

    .product__phone {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        vertical-align: middle;
        color: $primary-color;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;

        .site-en_US & {

        }
    }
}

.product-media{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__item{
        display: flex;
        width: 100%;

        @media (min-width: 768px){
            width: 32%;
            margin: 1%;
            &:nth-of-type(3n) {
                margin-right: 0;	
            }
            &:nth-of-type(3n+1) {
                margin-left: 0;	
            }
        }
        margin-bottom: 16px;
        padding: 2px;
        border: 1px solid $secondary-color;
        position: relative;
        @include transition(border 0.25s ease-in-out);
        &:hover{
            border-color: $default;
        }

        > h2, > p{
            display: none;
        }

        > a{
            display: block;
            width: 100%;
            > img{
                width: 100%;
            }
        }
        
        .embed-responsive{
            width: 100%;
        }
    }
}