.mosaico {
    
}

// griglia mosaico
.mosaico--grid {
    max-width: 1440px;
    margin: auto;
    padding: 0 15px;

    @media (min-width: 992px) {
        display: grid; 
        grid-template-columns: 41.66667% auto;
        grid-template-rows: 300px 300px;
        gap: 30px 30px;  
        justify-items: stretch; 
        align-items: stretch;
        flex-wrap: wrap;
    }

    @media (max-width: 991px){
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 30px;
    }

    @media (max-width: 767px){
        margin: 0 -15px;
    }
}

// item mosaico
.mosaico__item {
    .spot__text .h3 {
        display: block;
    }

    @media (max-width: 991px){
        flex: 0 0 100%;
        max-width: 100%;

        .spot {
            margin-bottom: 0;
        }
    }

    > * {
        height: 100%;
    }
}

.mosaico__item.bottom-right {
    @media (min-width: 992px){
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 30px; 
        grid-template-areas: 
          "little-spot-1 little-spot-2"; 
        grid-area: 2 / 2 / 3 / 3;
    }

    @media (max-width: 991px){
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    .little-spot {
        flex: 0 0 100%;
        max-width: 100%;

        > * {
            height: 100%;
        }
    }
    
    .little-spot-1 { 
        grid-area: little-spot-1; 
    }
    .little-spot-2 { 
        grid-area: little-spot-2; 
    }
}

.mosaico__item.top-right { 
    grid-area: 1 / 2 / 2 / 3; 
}

.mosaico__item.top-left { 
    grid-area: 1 / 1 / 2 / 2; 
}

.mosaico__item.bottom-left { 
    grid-area: 2 / 1 / 3 / 2; 
}

// Spot mosaic old aggiornamento
.spot-mosaic-right {
    @media (max-width: 767px){
        [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}