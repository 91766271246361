
:root{
    --foot-txt-color: #9b9b9b;
}

.site-footer {
    &--top {
        background-color: $grey-multi;
        padding: 47px 0;

        @media (max-width: 767px){
            padding: 30px 0;
        }
    }

    &--bottom {
        background-color: $default;
        padding: 30px 0;
        color: $grey-foot;
        font-size: $font-size-small;

        @media (max-width: 767px){
            background-color: $grey-multi;
            padding-top: 2rem;
        }
    }

    &__darker {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        @media (min-width: 992px){
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__newsl {
        text-align: center;
        margin: 0 auto;

        .h6 {
            display: inline-block;
            width: 100%;
            //text-align: right;
            color: $grey-foot;
            font-weight: bold;
            margin-top: 0;
        }

        input[type=email] {
            width: 210px;
            border-color: $grey-border;
            color: $grey-foot;

            /*@media (max-width: 1200px){
                width: 100%;
            }*/

            @media (max-width: 767px){
                text-align: center;
            }

            &:focus {
                border-color: $grey-foot;
            }
        }

        .btn {
            /*@media (max-width: 1200px){
                float: right;
                margin-top: 10px;
            }*/
            @media (max-width: 767px){
                width: 100%;
            }
        }

    }

    .copy-info {
        float: none;
        text-align: center;

        @media (min-width: 992px){
            float: left;
            text-align: left;
        }

        .darker {
            color: #606060;
        }
    }

    .footer-lang,
    .cont-lang {
        float: right;

        @media (max-width: 992px){
            margin-top: 40px;
            float: none;
            text-align: center;
        }
        
        .scmfonderie &,
        .superfici & {
            display: none;
        }
    }

    .footer-lang {
        display: none;

        .dropdown-toggle {
            min-width: 180px;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 2px solid #606060;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > span {
                @extend .h6;
                margin: 0;
            }
        }

        .dropdown-menu {
            width: 100%;
            text-align: center;

            top: auto;
            bottom: 100%;
            right: 0;
            left: auto;

            margin: 0;
            padding: 0;
            border: none;
        }

        &.open {
            .dropdown-menu {
                display: flex;
                flex-direction: column;
            }
        }   

        .dropdown-item {
            padding: 5px 10px;
            //padding-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //border-top: 1px solid #f0f0f0;
            background-color: $primary-color;

            &:first-child {
                padding-top: 10px;
                //border-top: none;
            }
            &:last-child {
                padding-bottom: 10px;
            }

            img {
                border: 1px solid rgba(0,0,0,.2);
            }

            > span {
                @extend .h6;
                margin: 0;
                color: white;
            }
        }
    }

    .footer-lang--superfici {
        .superfici & {
            display: inline-block;
        }
    }

    .footer-lang--fonderie {
        .scmfonderie & {
            display: inline-block;
        }
    }

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column;
        }
    }

    &__newsl {
        margin-bottom: 30px;

        .h6 {
            text-align: left;
        }
    }

    &__mkt {
        display: flex;
        //justify-content: center;
        flex-direction: column;
    }

    &__social {
        margin-top: 30px;
        margin-bottom: 30px;
        clear: right;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 992px){
            justify-content: center;

            .h6 {
                margin-right: 15px;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 0;
            margin-top: 1rem;
            width: 100%;
            //display: inline-block;
            clear: both;
            float: none!important;
            text-align: center;

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            > ul {
                display: inline-block;
                text-align: center;
                margin: auto;
            }
        }

        .h6 {
            color: var(--foot-txt-color);
            font-weight: bold;
            margin-right: 1rem;
        }

        ul {
            padding: 0;
            margin: 0;

            display: flex;
            gap: 15px;
        }

        ul > li {
            list-style: none;
            display: inline-block;

            a:hover {
                text-decoration: none;
            }
            
            &:hover {
                .icon {
                    border-color: $grey-foot;
                }
            }
        }

        .fa {
            font-size: 26px;
        }

        .icon {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            border: 2px solid $grey-border;
            //text-align: center;
            line-height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;

            @include transition(border .25s ease-in-out 0s);
        }

        .icon--svg {
            svg {
                fill: var(--foot-txt-color);
            }
        }
    }
}

.copy-info__txt {
    padding: 0 10px;

    @media (min-width: 768px){
        &.vat-number {
            padding-left: 20px;
        }
    }
}

.site-footer__menu {
    color: $grey-foot;
    @extend .clearfix;

    display: flex;
    flex-direction: row;

    @media (max-width: 992px) {
        justify-content: center;
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }

    h6 {
        margin-bottom: 15px;
        margin-top: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        min-width: 200px;
        max-width: 210px;
        font-size: 18px;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0px;
        }

        @media (max-width: 992px) {
            //min-width: inherit;
            text-align: center;
            margin-bottom: 30px;
        }

        @media (max-width: 767px) {
            max-height: 25px;
            overflow: hidden;
            transition: all .5s;

            &.open {
                max-height: 500px;

                > li > strong::after {
                    border-width: 1px 0 0 1px;
                    top: 8px;
                }
            }
        }
    }
    ul > li {
        margin-bottom: 15px;
        list-style: none;

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: 767px) {
            strong {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 4px;
                    right: -20px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-style: solid;
                    border-color: $grey-foot;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    transform-origin: center center;
                }
            }
        }
    }

    // 
    li > a {
        white-space: normal;
        word-break: break-word;


    }
}


.footer-corporate-link {
    width: 100%;
    margin-top: 10px;
    padding-top: 30px;
    text-align: center;
    display: none;

    @media (max-width: 767px){
        .is-home-sector &,
        .is-sector & {
            display: inline-block;
        }
    }

    > a {
        display: inline-block;
        margin-right: 0;
    }
}
