:root {
    --bg-scroll-btn: #033c73;
    --icon-size: 1.5rem;
}

.scroll-to-top {
    background-color: var(--bg-scroll-btn);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    //border: 1px solid white;
    opacity: 0;

    position: fixed;
    bottom: 100px;
    right: 1rem; // 30px
    z-index: 199;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: opacity .25s ease-in-out 0s;

    &.is-visible {
        opacity: 1;
    }

    //&:hover {
    //    opacity: 1;
    //}

    i {
        color: white;
        font-size: var(--icon-size);
    }
}
