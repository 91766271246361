html.search-is-visible {
	overflow-x: hidden;
	position: fixed;
	width: 100vw;
	height: 100vh;

	body {
		width: 100vw;
		height: 100vh;
		padding: 0;
		margin: 0;
	}
}

html.menu-is-open {
	//height: 100vh;
	//overflow: hidden;
}

@media (max-width: 767px) {
	.scroller {
		height: 100%;
	}

	.scroller {
		overflow-y: auto;
		//padding-top: 75px;
	}

	.scroller,
	.scroller-inner {
		position: relative;
	}

	.mp-container {
		position: relative;
		overflow: hidden;
		//background: #34495e;
	}

    .menu-trigger {
        color: $default;
        font-size: 22px;
        line-height: 75px;
    }
	.menu-trigger {
		color: $default;
		font-size: 22px;
		line-height: 75px;
	}

	.mp-pusher {
		position: relative;
		left: 0;
		height: 100%;
	}
}

.mp-menu {
	position: absolute; /* we can't use fixed here :( */

	top: 0;
	left: 0;
	z-index: 1;
	//width: 300px;
	width: 85%;
	height: 100%;
	//padding: 10px 0;
	//margin-top: 15px;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
	-webkit-transform: translate3d(0, 0, 0) rotate (0) scale (1);

	/*@media (max-width: 360px){
        width: 280px;
    }*/
}

.mp-level {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $white;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	content: "";
	opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
	background: rgba(255, 255, 255, 0.5);
	-webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
	-moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
	transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
}

.mp-pusher::after {
	background: rgba(0, 0, 0, 0.6);
}

.mp-level::after {
	z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.mp-level.mp-level-overlay {
	cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
	width: 100%;
	height: 100%;
	background: transparent;
	opacity: 1;
}

.mp-pusher,
.mp-level {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
	box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
	-webkit-transform: translate3d(-40px, 0, 0);
	-moz-transform: translate3d(-40px, 0, 0);
	transform: translate3d(-40px, 0, 0);
}

.mp-level {
	z-index: 100;
	overflow: hidden;

	/*> ul {
        height: 92vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }*/
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
	box-shadow: none;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* cover */
.mp-cover .mp-level.mp-level-open {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

/* content style */
.mp-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mp-menu ul li > a {
	display: block;
	padding: 8px 15px;
	outline: none;
	font-size: 18px;
	color: $default;

	-webkit-transition: background 0.3s, box-shadow 0.3s;
	-moz-transition: background 0.3s, box-shadow 0.3s;
	transition: background 0.3s, box-shadow 0.3s;

	&.first-level {
		padding: 10px 20px;
		font-size: 20px;
	}
}

.mp-menu ul li.icon-arrow-left > a {
	padding-right: 30px;
	position: relative;

	&:before {
		position: absolute;
		top: 0;
		right: 15px;
		z-index: -1;
		color: $secondary-color;
		font-size: 22px;
		height: 41px;
		line-height: 41px;
	}

	&.first-level:before {
		height: 48px;
		line-height: 48px;
	}
}

.mp-level > ul > li:first-child > a {
	//box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
}

.mp-menu ul li a:hover,
.mp-level > ul > li:first-child > a:hover {
	/*background: rgba(0,0,0,0.2);
	box-shadow: inset 0 -1px rgba(0,0,0,0);*/
}

.mp-menu .mp-level.mp-level-overlay > ul > li > a,
.mp-level.mp-level-overlay > ul > li:first-child > a {
	//box-shadow: inset 0 -1px rgba(0,0,0,0);
}

.mp-level > ul > li:first-child > a:hover,
.mp-level.mp-level-overlay > ul > li:first-child > a {
	box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0);
} /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

.mp-back {
	//background: $white;
	outline: none;
	color: $default;
	background-color: $grey-light;
	display: block;
	font-size: 18px;
	padding: 8px 15px;
	margin: 0 15px;
	margin-top: 15px;
	margin-bottom: 11px;
	position: relative;
	//box-shadow: inset 0 1px rgba(0,0,0,0.1);
	-webkit-transition: background 0.3s;
	-moz-transition: background 0.3s;
	transition: background 0.3s;

	.fa {
		margin-right: 15px;
		font-size: 22px;
		color: $secondary-color;
	}
}

/*.mp-back::after {
	font-family: 'linecons';
	position: absolute;
	content: "\e037";
	right: 10px;
	font-size: 1.3em;
	color: rgba(0,0,0,0.3);
}*/

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
	background: transparent;
	box-shadow: none;
	color: transparent;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
	//padding-left: 300px;
	//padding-left: 85%;
}

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
	display: none;
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
	display: block;
}

// Menù icons
.icon:before {
	font-family: "fontAwesome";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	display: inline-block;
	//margin-right: 0.6em;
	-webkit-font-smoothing: antialiased;
}
.icon-arrow-left > a:before {
	content: "\f105";
}

// Ricerca mobile
.mobile-nav__search {
	opacity: 0;
	visibility: hidden;

	//@include transition(visibility .1s ease-in-out 0s);
	//@include transition(opacity .25s ease-in-out .1s);

	padding: 0 15px;
	position: absolute;
	top: 75px;

	.input-cont {
		width: 100%;
		position: relative;
	}
	.input-cont input {
		font-size: 18px;
		width: 100%;
		padding-right: 30px;
	}
	.input-cont .fa {
		position: absolute;
		top: 3px;
		right: 0;
		color: $grey-foot;
	}

	.search-is-visible & {
		opacity: 1;
		visibility: visible;
		z-index: 1000;
		width: 100%;
		height: 100vh;
		background-color: $white;
	}
}

.mp-level[data-level="1"] > ul > li:first-child {
	margin-top: 15px;
}

.site-head__searchcont {
	.close-icon {
		width: 20px;
		height: 18px;
		margin-top: 26px;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		right: 0;

		@include transition(visibility 0.1s ease-in-out 0s);
		@include transition(opacity 0.15s ease-in-out 0.1s);

		@media (min-width: 992px) {
			display: none;
		}

		.search-is-visible & {
			opacity: 1;
			visibility: visible;
		}

		&:after,
		&:before {
			top: 0;
			margin-top: 9px;
			background-color: $default;
		}
	}

	.fa-search {
		opacity: 1;
		visibility: visible;

		.search-is-visible & {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.a-landing-new {
	.menu-trigger {
		display: none !important;
	}
}
