.product-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__toolbar{
        clear: both;
        ul{
            list-style-type: none;
            display: flex;
            flex-direction: row-reverse;  
            li{
                margin-left: 16px;
                &[data-type]{
                    cursor: pointer;
                    &.active{
                        color: $primary-color;
                    }
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }

    &.with-rows{
        .product-list__item a{
            &:after{
                content: "";
                display: block;
                clear: both;
            }
        }
        .product-list__item__image{
            width: 20%;
            float: left;
            margin-right: 16px;
            margin-bottom: 0px;
        } 
        .product-list__item__text{
            width: 75%;
            float: right;
        }
    }

    &.with-cols{
        .product-list__item{
            flex-direction: column;
            @media (min-width: 768px){
                width: 32%;
                margin-bottom: 1%;
                margin: 1%;
    
    
                &:nth-of-type(3n) {
                    margin-right: 0;	
                }
                &:nth-of-type(3n+1) {
                    margin-left: 0;	
                }
            }
        }

        .product-list__item__image{
            aspect-ratio: 29/16;
        } 
        @supports not (aspect-ratio: 29/16) {
            .product-list__item__image::before {
                float: left;
                padding-top: 55.17%;
                content: '';
            }
              
            .product-list__item__image::after {
                display: block;
                content: '';
                clear: both;
            }
        }

        .product-list__item__description{
            @extend .fading;
            height: 4.2em;
        }
    }

    &__item {
        display: flex;
        position: relative;
        width: 100%;
        margin-bottom: 16px;
        padding: 20px;
        border: 1px solid $secondary-color;
        @include transition(border 0.25s ease-in-out);

        &:hover{
            border-color: $default;
        }

        > a{
            display: block;
            width: 100%;
            text-decoration: none;
            color: $default;
            &:hover{
                text-decoration: none;
            }
        }

        &__image{
            margin-bottom: 32px;
            img{
                width: 100%;
            }
        }

        &__title{
            margin-top: 0;
            margin-bottom: 0;
        }

        &__description{
            *:last-child{
                margin-bottom: 0;
            }
        }

        &__material{
            margin-top: 16px;
        }

        &__machines{
            margin-top: 16px;
            a{
                margin-right: 16px;
            }
        }


        &__label{
            background-color: $primary-color;
            background-size: cover;
            color: #ffffff;
            //font-family: $condensed-font;
            padding: 0.5rem 1rem;

            display: inline-block;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

}

.flex-labels{
    column-gap: 5px;
    row-gap: 5px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}