.site-sidebar {
    font-size: 18px;

    ul {
        padding: 0;
    }
    li {
        list-style: none;
    }
    &__title {
        color: $secondary-color;
        margin-bottom: 30px;
        &.color-default {
            color: $default;
        }
    }
    &__links {
        width: 100%;
        margin-bottom: 23px;
        li {
            margin-bottom: 8px;
            //width: 100%;
        }
        li>a {
            display: inline-block;
            width: 100%;
            position: relative;
            &:focus,
            &:active,
            &:hover {
                text-decoration: none;
            }
        }
        &.custom a {
            padding: 10px 10px 10px 35px;
            background-color: $grey-light;
            position: relative;
            &:hover {
                background-color: $grey-hover;
            }
        }
        &.custom .fa {
            color: $secondary-color;
            font-size: 25px;
            position: absolute;
            left: 15px;
            top: 3px;
        }
    }
    &__list {
        margin-left: 0;
        margin-top: 10px;
        li {
            padding: 7px 0;
            a.active {
                font-weight: bold;
            }
        }
    }
    &__search {
        margin-bottom: 35px;
        .input-cont,
        input[type=text] {
            width: 100%;
        }
        .input-cont {
            position: relative;
        }
        input[type=text] {
            height: 30px;
        }
        input[type=text]::-moz-placeholder,
        input[type=text]::-webkit-input-placeholder {
            font-weight: bold;
            color: $default;
        }
        .fa {
            position: absolute;
            right: 0;
            bottom: 6px;
        }
    }
    &__menu {
        margin-bottom: 30px;
    }
    &__menu ul>li {
        padding-top: 14px;
        &:first-child {
            padding-top: 0;
        }
    }
    &__menu ul ul {
        padding-left: 15px;
        padding-top: 14px;
    }

    &__banner {
        padding: 55px 40px;
        position: relative;
        margin-top: 30px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(0,0,0,.5);
        }

        &:hover {
            .btn:before {
                width: 100%;
                background-color: #033c73;
            }
        }

        &__no-overlay:before{
            content: none;
            //background-color: transparent;
        }
    }
}

.banner {
    &__text {
        position: relative;
        z-index: 2;
    }    
    &__text,
    &__title {
        color: white;
    }
    .btn {
        color: white;
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .from-sidebar {
        margin: 0 -15px;
    }
}