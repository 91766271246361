
body.modal-open {
    overflow: hidden;
}

#garanzia-modal {

    .modal-backdrop {
        z-index: 0 !important;
    }
    .modal-dialog {
        width: 100vw;
        overflow: hidden;
        margin: 0;
    }
    .modal-dialog,
    .modal-content,
    .modal-body {
        height: 100vh;
    }
    .modal-content {
        border: none;
        border-radius: 0px;
        background-color: #f0f0f0;
    }
}

#garanzia-frame {
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: block;
    overflow: auto;
}