// Old layout
.case-studies {

    &__title {
        margin-bottom: 15px;
    }

    &__thumb {
        border: 1px solid $secondary-color;
        margin-bottom: 30px;
        min-height: 285px;

        &:hover {
            border-color: $default;
        }
    }

    &__item {
        //margin-bottom: 30px;

        h4 {
            margin: 0;
        }
    }

    &__caption {
        padding: 20px;
    }

}
