/* Scrollbar */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: transparent;
    
    @media (min-width:992px){
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #E4E4E4;
    }
}
::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;

    @media (min-width:992px){
        background-color: transparent;
    }
}
::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 10px solid transparent;

    @media (min-width:992px){
        border: 10px solid rgba(0,0,0,.2);
    }
}

html {
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scroll-behavior: smooth;
}

html,body {
    -webkit-backface-visibility: hidden;
}

// Windows fullscreen
html.form-is-open,
html.win-is-open {
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;

    .ios & {
        -webkit-transform: translate3d(0,0,0);
    }
}

.ios picture {
    -webkit-transform: translate3d(0,0,0);
}

@media (max-width: 1024px) {
    html.no-touchevents,
    .no-touchevents body {
        min-width: 1024px;
    }

    /*.container-fluid {
        width: 940px;
    }*/
}

@media (max-width: 767px){
    .row-fluid {
        //margin: 0 -15px;
    }
}

.no-hover{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

hr {
    border-width: 2px!important;
    border-color: #ccc!important;
}
