
.a-search.c-default {
    .s-prodlist__item {
        width: 100%;
    }

    .s-prodlist__item .media-body p {
        word-break: break-word;
    }

    .s-prodlist__item .media-left,
    .s-prodlist__item .media-body {
        display: inline-block;
        width: auto;
    }

    .s-prodlist__item .media-object {
        padding-right: 0;
    }

    @media (max-width: 767px) {
        .s-prodlist__grid .col-sm-9 {
            padding: 0;
        }

        .s-prodlist__item .media-left {
            margin-bottom: 10px;
        }
    }
}


body.a-search {
    .cd-hero-content {
        .cd-hero__title,
        .breadcrumb {
            display: none;
        }
    }
}
