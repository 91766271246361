
.title {
    &--icon {
        padding-left: 70px;
        position: relative;
        margin: 0;

        .fa {
            position: absolute;
            top: 50%;
            left: 0;
            font-size: 30px;
            margin-top: -15px;
            color: $secondary-color;
        }
    }

    // Slider
    &--big {
        letter-spacing: -2px;
        font-size: 60px;
        text-shadow: 0 2px 7px rgba(0,0,0,.2);
        color: $white;

        @media (max-width: 767px){
            font-size: 4rem;
        }

    }

}

.site-content {
    min-height: 200px;

    .is-content & {
        min-height: 50vh;
    }

    &.padding-big {
        padding: 60px 0;
    }

    &.padding-small {
        padding: 30px 0;
    }

    &.padding-medium {
        padding: 40px 0;
    }

    &.padding-custom {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    @media (max-width: 767px){
        &.padding-small,
        &.padding-medium,
        &.padding-big {
            padding: 30px 0;
        }
    }

    .container-fluid > .row {
        @media (min-width: 768px){
            margin-left: 0;
            margin-right: 0;
        }
    }

    // marketing automation content
    &--ma {
        min-height: 50vh;

        .page-title {
            text-align: center;
        }

        .btn-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 45px;

            > .btn {
                margin: 0 15px;
            }
        }
    }
}

// Only for article
.site-wrap {
    max-width: 1475px;
    margin: auto;

    &--readable {
        max-width: 1080px;
        //line-height: 1.6;
    }
}

// Read more
.read-more {
    max-height: 165px;
    overflow: hidden;
    position: relative;

    &.is-open {
        max-height: unset;
        padding-bottom: 50px;

        &:before {
            display: none;
        }
    }

    &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        //background: $white;
        background: linear-gradient(to bottom, rgba(255,255,255,0), $white 50%);
    }

    .btn {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
