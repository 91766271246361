
.btn {
    display: inline-block;
    padding: 8px 30px;
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 400;
    text-decoration: none;

    &:first-letter {
        text-transform: uppercase;
    }

    @include transition(border-color .25s ease-in-out);

    &:focus,
    &:active,
    &:hover {
        text-decoration: none;
        outline: 0;
        cursor: pointer;
    }

    &--filter {
        color: $default;
        background-color: $grey-light;
        // height: 40px;
        position: relative;
        font-size: 14px;
        padding: 8px 15px;

        @include transition(all .25s ease-in-out);

        &-blue{
            background-color: $primary-color;
            color: $white;
        }

        &:hover {
            background-color: $secondary-color;
        }

        &.active,
        &.is-checked {
            color: $white;
            background-color: $primary-color;
            &:hover{
                background-color: $primary-color;
            }
        }
    }

    &--rounded,
    &--rounded-tr {
        border-radius: 100px;
        text-align: center;
        border: 2px solid $primary-color;

        .site-footer & {
            color: $grey-foot;
            border-color: $grey-border;

            &:hover {
                border-color: $grey-foot;
            }
        }
    }

    &--rounded {
        background-color: $primary-color;
        color: $white;

        @include transition(all .25s ease-in-out 0s);

        &:active,
        &:hover {
            color: $primary-color;
            background-color: transparent;
        }
    }

    &--rounded-tr {
        color: $secondary-color;
        border-color: $secondary-color;
        background-color: transparent;

        .site-content & {
            color: $primary-color;
        }

        &:hover {
            border-color: $primary-color;
        }
    }

    &--link {
        position: relative;
        padding: 0;
        padding-bottom: 5px;
        color: $primary-color;
        border-bottom: 2px solid $secondary-color;
        
        .anchor-list &{
            margin-right: 16px;
        }

        &:before {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            padding: 0;
            overflow: hidden;
            content: '';
            height: 2px;
            background-color: $primary-color;

            @include transition(width 0.25s);
        }

        @media (min-width: 768px){
            &:hover {
                &:before {
                    width: 100%;
                }
            }
        }

    }

    &--link-wh {
        @extend .btn--link;
        color: $white;
        border-color: rgba($white,.4);

        &:before {
            border-color: $white;
        }
    }

    &--win-close {
        opacity: 1;
        @include transform(scale3d(1, 1, 1));
        @include transition(opacity 0.5s, transform 0.5s);
    }

    // Vecchi bottoni
    &--default {
        @extend .btn--rounded-tr;
        /*background-color: $grey-light;
        color: $primary-color;*/
    }
    &--primary {
        @extend .btn--rounded;
    }
    &--green {
        background-color: $state-success-bg;

        &:active,
        &:hover {
            color: $state-success-bg;
            border-color: $state-success-bg;
            background-color: transparent;
        }
    }
    &--nopadd {
        @extend .btn--primary;
    }
    &-primary {
        @extend .btn--primary;
    }

    &-lg {
        font-size: 22px;
    }
    &-sm {
        font-size: 14px;
    }
      
    &-block {
        display: block;
        width: 100%;
    }

    &--white {
        border-radius: 30px!important;
        color: black!important;
        background-color: white!important;
        border-color: white!important;
        transition: opacity .25 ease-in-out;

        &:hover {
            color: black!important;
            background-color: white!important;
            border-color: white!important; 
            opacity: .8;
        }
    }
}

// Button unstyled for action or event js
button {
    &.no-style {
        appearance: none;
        border: none;
        background: none;
    }
}
