.filter-contact {
    padding: 40px 0;

    /*&.style-2 {
        background-color: #f0f0f0;
        border-bottom: 3px solid $primary-color;
    }*/

    .flex-items--divide .dropdown-columns {
        grid-template-columns: 1fr;
        max-width: 80%;
        margin: auto;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @media (min-width: 992px){
            max-width: 70%;
            padding: 0;
        }
    }
}

.flex-items {
    display: flex;
    flex-direction: column;

    @media (min-width: 992px){
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    /*&--normal {
        max-width: 1400px;
        margin: auto;

        h3 {
            @extend .h2;
        }

        .flex-items__item:first-child {
            max-width: 65%;
            padding-bottom: 4rem;
        }

        .dropdown-columns {
            padding-left: 25%;
        
        }
    }*/

    > div:first-child {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        
        @media (min-width: 992px){
            border-top-right-radius: 0px;
            border-bottom-left-radius: 30px;
        }
    }

    &--width {
        //overflow: hidden;
        border-radius: 30px;
        border: 2px solid #f0f0f0;
        box-shadow:  0 0 60px rgba(0,0,0,.2);
        max-width: 100%;
        margin: auto;
    }

    &__item {
        max-width: 100%;
        flex-basis: 100%;
    }

    // divide col
    &--divide {
        max-width: 100%;
        flex-basis: 100%;

        @media (min-width: 992px){
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    &--padding {
        padding: 3.5rem;

        @media (min-width: 992px){
            padding: 6rem;
        }
    }

    &--bg {
        background-color: $primary-color;
        color: white;
    }
}