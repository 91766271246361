.cont-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white,.6);
    z-index: 100;
    display: none;

    @include transition(opacity .25s ease-in-out 0s);

    .form--loading & {
        display: block;
    }
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  top: 50%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: 100;
}

#loader-1:before, #loader-1:after{
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: $primary-color;
}

#loader-1:before{
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 10px solid #ccc;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
