:root {
    --base-color-text: #333;
    --base-bg-color: #f7f7f7;
}

.slider__contimg {
    &__img {
        margin: auto;
    }
}

.slider__contimg:not(.is-reviews) {
    min-height: inherit!important;
    
    .slider__caption {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &.text--align-center {
        .slider__caption {
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
        }
    }
    &.text--align-left {
        .slider__caption {
            left: 0;
        } 
    }
    &.text--align-right {
        .slider__caption {
            right: 0;
            text-align: right;
        }
    }

    &.not-image-mobile {
        @media (max-width: 992px){
            img {
                aspect-ratio: 4/3;
                object-fit: cover;
            }
            /*@supports not (aspect-ratio: 4/3) {
                padding-bottom: 133.33333333333%;
            }*/
        }
    }

    &__img {
        @media (max-width: 992px){
            //min-height: 100%;
            min-width: 100%;
            //object-fit: cover;
            //position: absolute;
        }
    }
}

.slider__contimg.is-reviews {
    align-items: center;

    .slick-initialized & {
        display: flex;
    }

    .slider__caption {
        margin: auto;
    }
}

.slider__caption .title {
    line-height: 1;
}
.slider__caption p {
    //line-height: 1;
    margin-bottom: 1rem;

    .text--standard & {
        font-size: 1.6rem;
    }
    .text--medium & {
        font-size: 2rem;
    }
    .text--big & {
        font-size: 2.5rem;
    }
}

.slider__caption .title,
.slider__caption p {
    .text--white & {
        color: white;
    }

    .text--black & {
        color: var(--base-color-text);
    }
}

.pimcore_area_slider {
    .slider__cont {
        min-height: inherit!important;
        background-color: var(--base-bg-color);
    }
}

.pimcore_area_reviews {
    .slider {
        margin-bottom: 3rem;
    }
}

.pimcore_area_slider,
.pimcore_area_reviews {
    .slick-arrow {
        top: 50%;
        transform: translateY(-50%);

        &:before {
            font-size: 2.5rem;
        }
    }
}