
.spot-image {
    background-color: $white;

    &--news {
        min-height: 470px;
        position: relative;

        @media (min-width: 1200px){
            min-height: 490px;
        }

        @media (max-width: 767px){
            min-height: inherit;
            height: auto;
            margin-bottom: 30px;
        }

        h4 {
            max-height: 3.6em;
            height: auto;
            overflow: hidden;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 2.2em;
                background: linear-gradient(to right, rgba(255,255,255,0), $white 95%);
            }
        }

        .spot-image__text {

            /*@media (max-width: 1200px){
                min-height: 255px;
            }*/

            h4 {
                overflow: hidden;
            }

            .btn {
                position: relative;
                bottom: 0;
                left: 0;

                /*@media (max-width: 1200px){
                    bottom: 20px;
                    left: 20px;
                }*/
            }
        }
    }

    .date {
        font-size: $font-size-small;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 8px;
    }

    .spot-mosaic-left & {
        height: 550px;
        position: relative;
    }

    .spot-mosaic-right & {
        height: 262px;
        position: relative;

        .spot-image__text {
            padding: 20px 30px;
            padding-bottom: 0;

            @media (min-width: 992px){
                padding: 10px 30px;
                
                .h4 {
                    font-size: 24px;
                }
            }
        }
    }

    @media (max-width: 767px){
        .spot-mosaic-left &,
        .spot-mosaic-right & {
            height: auto;
        }
    }

    &.with-border{
        border: 1px solid #ccc;

        .spot-image__text {
            padding: 20px;
        }
    }

}

.spot {
    &--mosaic {
        height: 550px;
        position: relative;

        @media (max-width: 767px){
            height: auto;
        }
    }

    &--bg {
        color: $white;
        background-color: #ccc;

        .spot-mosaic & {
            height: 262px;
            margin-bottom: 26px;

            @media (max-width: 767px){
                height: auto;
            }
        }
    }

    &--padd1 {
        padding: 80px 40px;
    }

    &--padd2 {
        padding: 30px 35px;

        &.spot--bg {
            min-height: 420px;
        }
    }

    &--padd1,
    &--padd2 {

        .content--asimmetric & {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 40px;
        }

        .spot__text {
            padding: 0;
        }
    }

    &--fixed {
        min-height: inherit;
        height: 494px;

        @media (max-width: 767px){
            height: auto;
        }

        h4 {
            max-height: 3.6em;
            height: auto;
            overflow: hidden;

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 2.2em;
                background: linear-gradient(to right, rgba(255,255,255,0), $grey-light 95%);
            }
        }
    }

    &--fairs {
        .spot-image__img {
            text-align: center;
            border: 1px solid $grey-light;
            background-color: white;
            // height: 190px;
        }

        .spot-image__img img {
            padding: 10px 0;
        }
    }

    // Mobile
    &--search {
        margin-bottom: 0!important;

        .s-prodlist & {
            margin-bottom: 30px!important;
        }

        .h4 {
            margin: 0;
            margin-bottom: 15px;
        }
    }
}

.spot,
.spot-image {
    position: relative;

    @media (min-width: 768px){
        &:hover .btn:before {
            width: 100%;
        }
    }

    &__text {
        padding: 30px 35px;

        @media (max-width: 1200px){
            padding: 20px;
            min-height: 255px;
        }

        h4,.h4 {
            margin-top: 0;
            display: block;
        }

        .h2 {
            display: block;
        }

        p {
            margin-bottom: 40px;
        }

        // Mobile rimuovo br
        @media (max-width: 767px){
            p > br {
                display: none;
            }
        }

        .spot-mosaic & {
            .btn {
                position: absolute;
                bottom: 20px;
                left: 35px;

                @media (max-width: 1200px){
                    //bottom: 20px;
                    left: 20px;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        /*.spot-mosaic-right & {
            padding: 20px 25px;

            .btn {
                bottom: 20px;
                left: 25px;
            }
        }*/

        .text {
            width: 100%;
            margin: 0 0 1em 0;
        }
        .text > .mini-desc {
            margin: 0;
        }
    }

    &__link {
        @include full;
    }

    &--colored {
        background-color: $grey-light;
    }

    &__img {
        aspect-ratio: 331/185;
            
        @supports not (aspect-ratio: 331/185) {
            &::before {
                float: left;
                padding-top: 55.89%;
                content: '';
            }
                
            &::after {
                display: block;
                content: '';
                clear: both;
            }
        }

        .spot-mosaic-right & {
            aspect-ratio: auto;
            max-height: 120px;
            overflow: hidden;

            @media (max-width: 992px){
                img {
                    width: 100%;
                }
            }
        }
    }
}

.spot-content {
    padding: 40px 45px;
    margin-bottom: 32px;

    .h2,
    h2 {
        margin-top: 0;
        margin-bottom: 15px;
    }

    p {
        line-height: 1.6;
        margin-bottom: 0px;
        //margin-bottom: 20px;
    }

    &--colored {
        background-color: $bg-spot;
        text-align: center;
    }

    .content--asimmetric & {
        margin-bottom: 30px;
    }

    .btn {
        //font-size: 16px;
        margin-top: 20px;
        line-height: 1.4;
    }

    p u{
        color: #8e0000;
        text-decoration: none;
    } 
}

.spot-box {
    background-color: $grey-light;

    @media (max-width: 767px){
        background-image: none!important;
    }

    > .site-wrap {
        position: relative;
        height: 100%;
    }

    &__caption {
        min-height: 260px;
        padding: 40px;
        position: absolute;
        bottom: 0;
        left: 30px;
        color: $white;
        //font-weight: lighter;

        &:hover .btn:before {
            width: 100%;
        }

        @media (max-width: 767px){
            position: static;
            left: 0;
            padding: 20px;
            min-height: inherit;
            background-color: $grey-foot;
        }
    }

    &--high {
        height: 435px;

        @media (max-width: 767px){
            height: auto;
        }
    }

    &--low {
        height: 260px;

        @media (max-width: 767px){
            height: auto;
        }

        .spot-box__caption {
            height: 100%;
        }
    }

    .btn {
        position: absolute;
        bottom: 40px;
        left: 40px;

        @media (max-width: 767px){
            position: static;
        }
    }
}

.spot-file {
    padding-left: 55px;
    position: relative;
    margin-bottom: 30px;
    min-height: 90px;

    .press-files & {
        min-height: inherit;
    }

    > .h5 {
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        word-break: break-word;
    }

    .fa {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 45px;
        color: $grey-foot;
    }

    .fa-file-pdf-o {
        &:after {
            content: 'PDF';
            font-family: Arial;
            font-size: 9px;
            font-weight: bold;
            position: absolute;
            bottom: -5px;
            left: 0;
        }
    }

    &--little {
        padding-left: 25px;
        margin-bottom: 10px;

        .fa {
            font-size: 16px;
        }
    }

    .btn {
        cursor: pointer;
    }

    &--with-preview{
        padding-left: 0px;
        min-height: 0px;

        > .h5{
            color: #333;
        }
    }

    &__thumb{
        border: 1px solid #eeeeee;
        padding: 4px;
        display: block;
        transition: 0.3s border-color;
        &:hover{
            border-color: #aaa;
        }
        img{
            width: 100%;
            display: block;
        }
    }
}

.spot-video {
    margin-bottom: 30px;

    > .embed-responsive {
        border: 1px solid $grey-light;
    }

    &__title {
        margin-top: 10px;

        > span {
            font-weight: bold;
        }

        &:after {
            width: 40%;
        }
    }

    h2,p {
        display: none;
    }
}

// 4 Brick

.spot {

    @media (max-width: 767px) {
        margin-bottom: 30px;
    }

    &--bg {
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #ccc;
    }

    &--colored {
        &--secondary,
        &--grey {
            color: #333;

            .btn {
                color: #033c73;
                border-bottom: 2px solid #ccc;
            }

            &:hover {
                .btn:before {
                    border-color: $primary-color;
                }
            }
        }

        &--grey {
            background-color: #f0f0f0;
        }

        &--secondary {
            background-color: rgba(193,203,223,0.45);
        }

        &--primary {
            background-color: $primary-color;
        }
    }

    // Impostazione generata per cms piazza prodotti
    &--fix-height {
        min-height: inherit;
        height: 300px;
        margin-bottom: 30px;

        .spot__text {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            &.justify-content-start{
                justify-content: flex-start;
            }
        }
    }

    &--4_3 {
        aspect-ratio: 4 / 3;
        margin-bottom: 30px;

        @supports not (aspect-ratio: 4 / 3) {
            height: 0;
            overflow: hidden;
            padding-top: 4 / 3 * 100%;
        }
    }

    &--md {
        min-height: 40vh;
    }
    &--lg {
        min-height: 50vh;
    }
    &--xl {
        min-height: 60vh;
    }

    &.is-dark {
        .spot__text {
            color: white;
        }    

        .btn {
            @extend .btn--link-wh;
        }
    }

    &.is-light {
        .spot__text {
            color: #333;
        }
        
        .btn {
            @extend .btn--link;
        }
    }

    // Nuova versione cover image senza data-bg
    &.cover {
        overflow: hidden;

        picture > img {
            position: absolute;
            object-fit: cover;
            min-height: 100%;
            min-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        .spot__text {
            position: relative;
            z-index: 5;
            height: 100%;
        }
    }
}

.spot-enfasy {
    &--cols {
        display: flex;
        flex-direction: row;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        ._item {
            width: 50vw;
            height: 435px;
            display: flex;
            align-items: center;
            position: relative;

            &:nth-child(1) {
                justify-content: flex-end;
            }

            &:nth-child(2) {
                justify-content: flex-start;

                ._item__caption {
                    background-color: rgba(3, 60, 115, 0.8);
                }
            }

            @media (max-width: 992px) {
                width: 100vw;
                /*align-items: center!important;
                justify-content: center!important;*/
            }

            &__caption {
                display: flex;
                flex-direction: column;
                justify-content: center;

                width: 80%;
                color: white;
                padding: 0 40px;
                min-height: 285px;
            }

            .btn {
                margin-top: 35px;
            }

            &:hover {
                .btn:before {
                    width: 100%;
                }
            }
        }
    }
}

.spot-col-img {
    display: flex;
    align-items: center;
    min-height: 300px;
    background-color: #f0f0f0;
    flex-flow: row wrap;

    &.img-right {
        flex-flow: row-reverse wrap;    
    }

    ._image {
        width: 30%;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 767px) {
            width: 40%;
        }
    }

    ._text {
        width: 70%;
        padding: 0 40px;

        @media (max-width: 767px){
            width: 60%;
            padding: 0 20px;
        }
    }
}
