
.win-fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    pointer-events: none;
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include transition(opacity .5s ease-in-out 0s);

    -webkit-transform: translateZ(0);

    &:after,
    &:before {
        content: '';
        position: absolute;
        pointer-events: none;

        @supports (-webkit-appearance:none) {
            width: calc(100% + 15px);
            height: calc(100% + 15px);
            border: 1.5em solid rgba($secondary-color,.85);
        }

        @include transform(translate3d(0, 0, 0));

        @media (max-width: 1024px){
            display: none;
        }
    }

    &--open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    .close-icon {
        position: absolute;
        top: 1.7em;
        right: 1.7em;
        cursor: pointer;
        width: 40px;
        height: 40px;

        @media (max-width: 767px){
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            opacity: .8;
        }

        &:after,
        &:before {
            background-color: $secondary-color;
        }
    }
}

.win-fullscreen-search,
.win-fullscreen-lang {
    @extend .win-fullscreen;
}

.win-fullscreen-search {

    &__form {
        width: 70%;
        margin: 5em 0;

        opacity: 0;
        @include transition(opacity 0.5s, transform 0.5s);

        .win-is-open & {
            opacity: 1;
            @include transform(scale3d(1, 1, 1));
        }
    }

    &__input {
        font-family: inherit;
    	font-size: 5vw;
        line-height: 1;
    	display: inline-block;
    	box-sizing: border-box;
    	width: 100%;
        height: auto!important;
    	padding: 0.05em 0;
    	color: $primary-color;
        border: none;
    	border-bottom: 2px solid;
        outline: 0;
    }

    &__info {
        font-size: 90%;
    	font-weight: bold;
    	display: block;
    	width: 100%;
    	margin: 0 auto;
    	padding: 0.85em 0;
    	text-align: right;
    	color: $primary-color;
    }

    &__related {
        display: flex;
	    width: 70%;
	    pointer-events: none;
        color: $primary-color;

        /*.with-lang & {
            display: inline-block;
        }*/
    }

    &__suggestion {
        width: 33.33%;
    	text-align: left;

        @media (max-width: 480px) {
            width: 100%;
        }

        &:nth-child(2){
            margin: 0 3em;
        }

        .h3 {
            font-size: 1.35em;
        	margin: 0;
        }

        .h3::before {
            content: '\21FE';
            display: inline-block;
            padding: 0 0.5em 0 0;
        }

        p {
            font-size: 1.15em;
        	line-height: 1.4;
        	margin: 0.75em 0 0 0;
        }
    }
}

.win-fullscreen-search__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	opacity: 0.3;
	color: $grey-border;
}

.win-fullscreen-search__input::-moz-placeholder {
	opacity: 0.3;
	/* Mozilla Firefox 19+ */
	color: $grey-border;
}

.win-fullscreen-search__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	opacity: 0.3;
	color: $grey-border;
}

.win-fullscreen-search__input::-webkit-search-cancel-button,
.win-fullscreen-search__input::-webkit-search-decoration {
	-webkit-appearance: none;
}

.win-search__input::-ms-clear {
	display: none;
}

.win-fullscreen-lang {
    //background-color: $white;
    top: auto;
    bottom: 0;

    &__related {
        //display: flex;
	    max-width: 760px;
	    color: $primary-color;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

///////
// With form per scheda prodotto

.form-fullscreen {
    @extend .win-fullscreen;
    text-align: left;
    //background-color: $white;

    &.is-open {
        pointer-events: auto;
        opacity: 1;
        overflow: hidden;
    }

    .close-icon {
        z-index: 50;
    }

    @media (max-width: 767px){
        .form-wrap form > .row,
        .form-wrap > .row {
            margin: 0 -15px;
        }

        .form-wrap .head-form {
            margin-top: 15px;
        }
    }

}

// Scroller form row
.form-fullscreen__scroller {
    overflow: auto;
    margin-top: 66px;
    margin-bottom: 33px;
    padding: 1 2rem;

    @media (min-width: 992px){
        padding: 0 2rem
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px transparent;
        background-color: transparent;
        
        @media (min-width:992px){
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
            background-color: #E4E4E4;
        }
    }
    &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;

        @media (min-width:992px){
            background-color: transparent;
        }
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: 3px solid transparent;

        @media (min-width:992px){
            border: 3px solid rgba(0,0,0,.2);
        }
    }

    .ajax-form {
        margin-top: 0px;
    }

    .form-wrap {
        max-width: 1440px;
    }
}
