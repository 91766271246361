*[data-toggle="collapse"]{
    cursor: pointer;
    &:after {
        font-family: fontAwesome;
        display: inline-block;
        padding: 0 20px;
        font-size: 14px;
        vertical-align: middle;
    }
    &[aria-expanded="false"]{
        &:after{
            content: $fa-var-chevron-down;
        }
    }
    &[aria-expanded="true"]{
        &:after{
            content: $fa-var-chevron-up;
        }
    }
}

// Extera: Custom accordion generica
.accordion--generic {
    .card {
        min-height: inherit;
        background-color: transparent!important;
        border-bottom: 1px solid #ccc;

        &:nth-last-child(1) {
            border: none;
        }
    }
    .card-header h2 {
        margin-bottom: 0;
    }
    .card-header .btn {
        border: none;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        background-color: transparent!important;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            padding-right: 0;
        }
    }
}

.content-centered .accordion--generic {
    .collapse {
        display: block!important;
        visibility: hidden;
        opacity: 0;
        height: 0;

        &.in {
            height: auto;
            visibility: visible;
            opacity: 1;
        }
    }
}