
.spot-enfasy {
    margin-bottom: 60px;

    .spot-box {
        position: relative;
    }

    .spot-box:hover .spot-box__caption .btn:before {
        width: 100%;
    }

    &--single{
        margin-bottom: 0;
    }
}

.spot-mosaic {
    margin-bottom: 30px;

    @media (max-width: 992px){
        padding: 0 15px;
    }

    .title-section {
        margin-top: 0;
        margin-bottom: 30px;

        @media (max-width: 767px){
            margin: 0 -15px;
            margin-bottom: 30px;
        }
    }

    @media (max-width: 767px){
        .spot-mosaic-left {
            padding: 0;
        }
    }

    @media (max-width: 767px){
        .spot-mosaic-left .spot-image {
            margin-bottom: 30px;
        }
    }
}

// Home network CMS
.list-cms {
    margin: 3rem 0;

    &__item {

        @media (max-width: 1024px) {
            margin-bottom: 4rem;
            //min-height: 175px;
        }

        .img,
        .logo {
            margin-bottom: 24px;
            display: inline-block;

            @media (max-width: 1024px) {
                margin-bottom: 15px;
            }
        }

        .img {
            width: 100%;
            height: 85px;
        }

        .logo {
            max-width: 140px;

            @media (max-width: 767px){
                max-width: 105px;
            }
        }

        .btn {
            font-size: 25px;
            color: #6a7b8f;
            font-weight: bold;

            /*@media (min-width: 1200px){
                &:before{
                    height: 42px;
                }
            }*/

            @media (max-width: 1200px) {
                font-size: 16px;

                /*&:before{
                    height: 29px;
                }*/
            }
            
        }
    }
}

.a-home-group .list-cms__item {
    @media (max-width: 1200px) {
        margin-bottom: 30px;
        min-height: inherit!important;
    }

    .btn {
        font-size: 17px;

        /*@media (min-width: 992px) {
            &:before {
                height: 30px;
            }
        }*/
    }
}
