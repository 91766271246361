.subnav {
    background-color: $default;
    width: 100vw;
    position: absolute;
    top: 100px;
    visibility: hidden;
    left: 0;
    opacity: 0;
    z-index: 10;
    padding: 30px 18px;
    display:flex;
    flex-direction: row;
    justify-content: center;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 0;
        width: 0;
        margin-left: -4px;
        border: 8px solid transparent;
        border-top-color: #ffffff;
        opacity: 1;
        visibility: visible;
    }

    &__col{
        margin-right: 30px;
        padding-right: 30px;
        position: relative;

        &:after{
            display:block;
            content: "";
            background-color: #ffffff;
            width: 1px;
            height: 100px;
            position: absolute;
            top: 12px;
            right: 0px;
        }

        &:last-child{
            margin-right: 0;
            padding-right: 0;
            &:after{
                display: none;
            }
        }

        ul{
            padding-left: 0;
            li{
                line-height: 1.6em;
                padding-left: 0;
                margin-bottom: 14px;
                a {
                    font-size: 16px;
                    line-height: 1.4;
                    color: $white; 
                }
                ul{
                    padding-top: 14px;
                    padding-left: 20px;
                }
            }
        }

        &--multi-2{
            ul{
                column-count: 2;
                column-gap: 20px;
            }
        }
        &--multi-3{
            ul{
                column-count: 3;
                column-gap: 20px;
            }
        }
    }

    &__title{
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__inline {
        text-align: center;

        ul {
            margin: 0;
            padding: 0;
            line-height: 1.2em;
            
            > li {
                display: inline-block;
                margin: 0 20px;

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }

                > a {
                    font-size: 16px;
                    line-height: 1.4;
                    margin-bottom: 14px;
                    color: $white;
                } 

                &.active{
                    > a{
                        font-weight: bold;
                    }
                }

                @media (max-width: 1200px){
                    margin: 0 10px;
                }
            }
        }
    }
}
