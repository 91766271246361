
// Hero customization
.cd-hero {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    // custom only with content
    &.with-content {
        &:before {
            content: '';
            display: flex;
            width: 100%;
            padding-top: 50%;
            background-color: rgba(0,0,0,.15);
    
            @media (min-width: 767px){
                padding-top: 35%;
            }
    
            @media (min-width: 992px){
                padding-top: inherit;
            }
        }

        @media (min-width: 992px){
            height: auto;
            display: table;
    
            &:before {
                display: none;
            }
        }

        // site wrap mod
        .site-wrap {
            position: absolute;
            
            @media (max-width: 992px){
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
            }
    
            @media (min-width: 992px){
                position: static;
            }
        }
    } // end custom data

    .lead {
        color: white;
        line-height: 1.2;

        @media (min-width: 767px){
            line-height: 1.4;
        }
    }
}

// content hero
.cd-hero-content {
    min-height: 140px;
    padding: 30px 0;
    margin-bottom: 2rem;

    .with-content & {
        @media (max-width: 992px){
            min-height: inherit;
            height: auto!important;
        }
    }
}

// Video
.cd-hero.with-video {
    background-color: white;
    display: inline-block;

    .site-wrap {
        position: relative!important;
        transform: none!important;
    }

    &:before,
    &:after {
        display: none;
    }
}
.cd-hero__video {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
}
.cd-hero__content {
    width: 100%;
    position: absolute!important;
    bottom: 0;
    z-index: 10;
    padding: 0 15px;
}

// Presente in minisite template
.cd-hero-image {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &.brick-version {
        aspect-ratio: 4 / 3; // 800 x 600

        @media screen and (min-width: 800px){
            aspect-ratio: 16 / 7; // 1920 x 840
        }

        img {
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__content {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;
        z-index: 10;

        @media screen and (min-width: 768px) {
            margin-bottom: 2rem;
        }

        .cd-hero-content {
            height: auto;
        }

        p,
        .lead {
            color: white;
            text-wrap: balance;
        }

        &.is-centered {
            bottom: 50%;
            transform: translateY(50%);
        }

        &.is-light {
            .cd-hero__title,
            p,
            .lead { 
                color: $primary-color;
            }
        }
        
        @media screen and (min-width: 800px){
            &.to-right,
            &.to-left {
                min-width: 20%;
                max-width: 50%;
            }
            &.to-right {
                left: auto;
                right: 2rem;
            }
    
            // non c'è
            &.to-left {
                right: auto;
                left: 2rem;
            }
        }
    }

}