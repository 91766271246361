
.presentation {
    text-align: center;

    h1 {
        margin-bottom: 40px;
        line-height: 1.4;

        .is-home-sector & {
            margin-bottom: 15px;
        }

        @media (min-width: 992px){
            padding: 0 10%;
        }

        @media (max-width: 767px){
            line-height: 1.2;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 2;
        max-width: 60%;
        margin: auto;
        margin-bottom: 40px;
        text-align: left;

        .is-home-sector & {
            margin-bottom: 30px;
        }

        @media (max-width: 767px){
            max-width: 100%;
            line-height: inherit;
            margin-bottom: 0;
        }

        a {
            font-weight: bold;
            color: $primary-color;
        }
    }
}

.list-categories {
    padding: 0;
    margin: 0;
    margin-bottom: 40px;

    li {
        list-style: none;
        display: inline;
        margin-right: 40px;

        @media (max-width: 767px){
            margin: 0 12px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    li > a {
        @extend .btn--link;
        font-weight: 400;
        font-size: 32px;
        word-break: break-word;

        @media (max-width: 767px){
            font-size: 20px;
            margin-bottom: 5px;
            display: inline-block;
        }

        &:first-letter {
            text-transform: uppercase;
        }

        @media (min-width: 768px){
            &:focus,
            &:active,
            &:hover {
                text-decoration: none;
            }
        }

        &:before {
            height: 45px;
            border-bottom: 2px solid $secondary-color;

            @media (max-width: 767px){
                height: 35px;
            }
        }
    }

    .list-category {
        &--wood {
            color: $wood;
        }
        &--glass {
            color: $glass;
        }
        &--plastic {
            color: $plastic;
        }
        &--metal {
            color: $metal;
        }
        &--stone {
            color: $stone;
        }
        &--advanced {
            color: $advanced;
        }
    }

    .list-category--wood:hover {
        &:before {
            border-color: $wood;
        }
    }

    .list-category--glass:hover {
        &:before {
            border-color: $glass;
        }
    }

    .list-category--plastic:hover {
        &:before {
            border-color: $plastic;
        }
    }

    .list-category--metal:hover {
        &:before {
            border-color: $metal;
        }
    }

    .list-category--stone:hover {
        &:before {
            border-color: $stone;
        }
    }

    .list-category--advanced:hover {
        &:before {
            border-color: $advanced;
        }
    }
}

.home-news,
.sectors {
    background-color: $grey-bg;
}

.sectors {
    padding: 50px 0 40px;
    &-wrap {
        max-width: 1195px;
        margin: auto;

        .container-fluid {
            padding: 0 1em;
        }
    }

    &__item {
        max-width: 460px;

        @media (max-width: 992px){
            max-width: 100%;
        }

        ul {
            padding: 0;
            margin: 0 -15px;
            margin-top: 35px;
        }
    }

    &__fil {
        list-style: none;
        float: left;
        padding: 0 15px;
        margin-bottom: 11px;
        max-width: 50%;
        width: 100%;

        .site-ru & {
            min-height: 153px;
        }

        @media (max-width: 360px){
            min-height: 128px;
        }

        .cont-img {
            max-width: 213px;
            border: 2px solid $secondary-color;
            text-align: center;
            position: relative;

            @include transition(all .25s ease-in-out);

            .sectors__item--mach & {
                height: 250px;
                line-height: 250px;

                @media (max-width: 767px){
                    height: 150px;
                    line-height: 150px;
                }
            }
            .sectors__item--small &,
            .sectors__item--comp & {
                height: 100px;
                line-height: 100px;

                @media (max-width: 767px){
                    height: 75px;
                    line-height: 75px;
                }
            }
        }

        img {
            vertical-align: middle;

            &.percentage {
                max-width: 90%;
                padding: 0;
            }

            @media (max-width: 767px){
                padding: 0 10px;
            }
        }

        p {
            margin-bottom: 0;
        }

        .desc {
            display: inline-block;
            padding-top: 10px;
            margin-bottom: 5px;
            font-size: $font-size-small;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            position: relative;
            color: $default;

            @media screen and (min-width: 768px){
                min-height: 70px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $secondary-color;
                left: 0;
                bottom: -30px;
                opacity: 0;
            }
        }

        &:hover .desc {
            color: $primary-color;
        }

        //a > .desc {
        //    text-decoration: underline;
        //}

        @media (min-width: 768px){
            &:hover .cont-img {
                background-color: $white;
                border-color: $white;

                @include transition(all .25s ease-in-out);
            }
        }

        /*&:hover .desc::after {
            bottom: -10px;
            opacity: 1;

            @include transition(opacity .25s ease-in .25s, bottom .25s);
        }*/
    }
}

.home-news {
    padding-bottom: 2.5%;

    .h4 {
        margin-top: 0;
        margin-bottom: 30px;
    }

    &-title {
        margin-top: 0;
        margin-bottom: 30px;
        display: inline-block;
    }

    .is-home-sector & {
        padding: 50px 0;

        @media (max-width: 767px){
            padding: 30px 0;
        }
    }

    @media (max-width: 767px){
        .row-fluid {
            margin: 0 -15px;
        }
    }
}
