
.press-release {
    &__data {
        margin-left: 10px;
    }

    &__part {
        margin: 30px 0;
    }

    &__lang:first-letter {
        text-transform: uppercase;
    }

    &__lang {
        text-transform: capitalize;
    }
}

.press-gallery {
    &--zip {
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

.press-files {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px; 

    li {
        list-style: none;
    }

    li > a {
        word-break: break-word;
    }
}
