
.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.fading {
    overflow: hidden;
    position: relative;
    height: 5.4em; /* exactly three lines */

    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255,255,255,0), $white 95%);
        //visibility: hidden;

        .spot-image--colored & {
            background: linear-gradient(to right, rgba(255,255,255,0), $grey-light 95%);
        }
    }

    // rimuovo per problemi con bg
    &:after {
        display: none;
    }

}
.fading-2-line {
    @extend .fading;
    height: 2.8em;
}
.fading-3-line {
    @extend .fading;
    height: 4.2em;
}
.fading-4-line {
    @extend .fading;
    height: 5.5em;
}
.fading-5-line {
    @extend .fading;
    height: 7em;
}
.fading-6-line {
    @extend .fading;
    height: 8.2em;
}

.full-link {
    @include full;
}

.with-flex {
    display: flex;
    flex-direction: row;
    //align-items: center;
}

.cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}

.white-opa {
    background-color: rgba(255,255,255,.4);
}
.black-opa {
    background-color: rgba(0,0,0,.8);
}

.block {
    display: block;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.align-center {
    text-align: center;
}
.align-left {
    text-align: left;
    float: left;
}
.align-right {
    text-align: right;
    float: right;
}

img.img-rounded {
    border-radius: 50%;

    &.to-small {
        width: 16px;
        height: 16px;
    }

    &.to-medium {
        width: 32px;
        height: 32px;

        @media (max-width: 360px){
            width: 20px;
            height: 20px;
        }
    }
}


.remove-padding-xs {
    @media (max-width: 767px){
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.remove-margin-xs {
    @media (max-width: 767px){
        margin: 0 -15px;
    }
}

// Spacing
.space--sm {
    padding: 30px 0;

    @media (max-width: 767px){
        padding: 20px 0;
    }
}
.space--md {
    padding: 50px 0;

    @media (max-width: 767px){
        padding: 30px 0;
    }
}
.space--lg {
    padding: 70px 0;

    @media (max-width: 767px){
        padding: 40px 0;
    }
}

.margin {
    &-y--sm {
        margin: 30px 0;

        @media (max-width: 767px){
            margin: 20px 0;
        }
    }

    &-y--md {
        margin: 50px 0;

        @media (max-width: 767px){
            margin: 30px 0;
        }
    }

    &-y--lg {
        margin: 70px 0;

        @media (max-width: 767px){
            margin: 40px 0;
        }
    }

    &-top {
        &--sm {
            margin-top: 30px;

            @media (max-width: 767px) {
                margin-top: 25px;    
            }
        }

        &--md {
            margin-top: 50px;

            @media (max-width: 767px) {
                margin-top: 35px;    
            }
        }

        &--lg {
            margin-top: 70px;

            @media (max-width: 767px) {
                margin-top: 50px;    
            }
        }
    }

    &-bottom {
        &--sm {
            margin-bottom: 30px;

            @media (max-width: 767px) {
                margin-bottom: 25px;    
            }
        }

        &--md {
            margin-bottom: 50px;

            @media (max-width: 767px) {
                margin-bottom: 35px;    
            }
        }

        &--lg {
            margin-bottom: 70px;

            @media (max-width: 767px) {
                margin-bottom: 50px;    
            }
        }
    }
}

// Bootstrap

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-md-5ths {
    width: 100%;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-md-5ths {
        width: 50%;
    }
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-md-5ths,
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

// Flex grid

.flex-row {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}

.flex-item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 992px){
        flex: 0 0 50%;
        max-width: 50%;
    }
}
