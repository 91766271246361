
.secondary-nav {
    height: 70px;
    line-height: 70px;
    background-color: $grey-light;
    overflow: hidden;

    .case-studies & {

    }

    &.is_stuck {
        z-index: 50;
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        float: left;
        margin: 0 20px;

        &:first-child {
            margin-left: 0;
        }
    }

    li > a {
        color: $grey-multi;
        font-size: 18px;

        &:hover {
            text-decoration: underline;
        }
    }

    li.active > a {
        font-weight: bold;
    }

}
