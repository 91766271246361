.dropdown-columns {
    display: grid;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 640px){
        grid-template-columns: repeat(3, 1fr);
    }

    button[disabled] {
        opacity: .6;
        cursor: not-allowed;
    }

    .dropdown {
        background-color: $grey-bg;
        border-bottom: 2px solid $secondary-color;

        button {
            width: 100%;
            background: transparent;
            border: none;

            display: flex;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 5px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
        }

        &.open {
            border-color: $primary-color;
        }
    }

    .dropdown-menu {
        width: 100%;
        //background-color: $primary-color;
        margin: 0;
        padding: 0;
        border-radius: 0px;

        max-height: 280px;
        overflow: auto;

        li[data-sector] {
            display: none;
        }

        li > span {
            display: block;
            font-weight: 400;
        }

        li > span,
        li > a {
            line-height: 1.2;
            padding: 10px 20px;
            color: white;
            cursor: pointer;
            background-color: $primary-color;
            transition: background-color .25s ease-in-out;

            &:hover {
                //opacity: .8;
                --primaryRGB: 3,60,115;
                background-color: rgba(var(--primaryRGB), .8);
            }
        }
    }
}