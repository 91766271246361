
.slick-carousel {
    background-color: #f0f0f0;
    padding-top: 50px;
    padding-bottom: 70px;

    // BG
    &.bg--white {
        background-color: transparent;
        margin-bottom: 30px;
    }

    a,
    button,
    .slick-slide {
        outline: 0;
    }

    // lista slick item
    .slick-list {
        margin-bottom: 30px;
    }

    &__head {
        margin-bottom: 30px;
    }

    &__item {
        background-color: white;
        width: 100%;
        min-height: 300px;
        //border: 1px solid #ccc;
        position: relative;
        display: block;
        text-align: center;
        //margin-bottom: 30px;
        outline: 0;
        overflow: hidden;

        img {
            //width: 100%;
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            width: auto;
            max-width: inherit;
            
            transform: scale(1);
            transition: transform .5s ease;
        }

        &:hover img {
            transform: scale(1.1);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba(0,0,0,.4) 25%, rgba(0,0,0,.1) 100%);
            z-index: 2;

            .no-shade & {
                display: none;
            }
        }

        .item__title {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            color: white;
            font-size: 22px;
            font-weight: 400;
            z-index: 10;            
         }
    }

    .slick-carousel__cont:not(.arrow-between){
        .slick-arrow {      
            width: 30px;
            height: 30px;
            line-height: 30px;      
            bottom: -45px;
            border-radius: 50%;
            border: 1px solid #4a4a4a;
            //text-indent: -19999px;
    
            transition: opacity .25s ease;
    
            &:hover {
                opacity: .8;
            }
    
            &.slick-prev:before,
            &.slick-next:before {
                font-size: 18px;
                color: #4a4a4a;
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
            &.slick-prev:before {
                padding-right: 3px;
            }
            &.slick-next:before {
                padding-left: 3px;
            }
            &.slick-prev {
                left: 50%;
                margin-left: -45px;
            }
            &.slick-next {
                right: 50%;
                margin-right: -45px;
            }        
        }
    }

    .arrow-between {
        .slick-arrow {
            top: 50%;
            transform: translateY(-50%);
            
            &:before {                
                font-weight: 400;
            }

            &.slick-prev {
                left: -25px;
            }
            &.slick-next {
                right: -25px;
            }
        }
    }
}

// Multicol CMS piazza prod
.slick-carousel,
.slick-carousel-multicol {
    .slick-arrow {
        position: absolute;
        text-align: center;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        margin-top: -10px\9; /*lte IE 8*/
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        z-index: 30;

        &:before {
            display: inline-block;
            font-family: 'FontAwesome';
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 30px;
            color: #ccc;
        }
    
        &.slick-prev:before {
            content: '\f053';
        }
        &.slick-next:before {
            content: '\f054';
        }
    }
}

.slick-carousel-multicol {
    margin-top: 15px;

    .slick-carousel__item {
        margin-bottom: 30px;
    }

    // frecce sotto
    .slick-next,
    .slick-prev {
        bottom: -20px;

        &:before {
            font-size: 20px;
            color: #333;
        }
    }

    .slick-next {
        right: 0;
    }
    .slick-prev {
        right: 50px;
    }
}