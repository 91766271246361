
body.site-ru {
  font-family: $roboto;
  font-weight: 300;

  .btn,
  .site-nav__item,
  .title--big,
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6,
  .little-title,
  .fancybox-error,
  .fancybox-title {
    font-family: $roboto;
  }

  .presentation .list-categories li > a {
    font-family: $roboto;
  }

  // Nav custom - porting to official
  .site-head .with-flex {
    height: 100px;
    column-gap: 10px;
  }
  .site-head > .site-wrap {
    line-height: inherit;
  }
  .site-nav > ul {
    height: auto;
    line-height: inherit;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .site-nav > ul > li {
    float: none;
    height: 100px;
  }
  .site-nav__item {
    font-size: 16px;
    line-height: 1.2;
    padding: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

