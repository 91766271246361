
.site-nav {
    justify-content: center;
    margin: 0 auto;

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 10px;

        @media (min-width: 1200px){
            gap: 20px;
        }
    }

    > ul > li {
        list-style: none;

        &:first-child {
            > a {
                padding-left: 0;
            }
        }
        &:last-child {
            > a {
                padding-right: 0;
            }
        }
    }

    &__item {
        font-size: 20px;
        font-weight: 400;
        color: $default;
        height: 100px;
        line-height: 100px;
        display: inline-block;
        //padding: 0 20px;

        &:hover,
        &:active,
        &:focus{
            text-decoration: none;
        }

        /*@media (max-width: 1200px){
            padding: 0 10px;
        }*/

        @media (max-width: 1024px){
            font-size: 18px;
        }

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .has-dropdown{
        &:hover{
            > a{
                color: $primary-color;
            }

            .subnav {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    > ul > li.active {
        .site-nav__item {
            font-weight: bold;
        }
    }

    &.site-nav--small-size {
        .site-nav__item {
            font-size: 16px;
        }
    }
}
