
.list-tag {
    display: inline-block;
    list-style: none;
    margin-top: 0px;

    span{
        display: block;
    }

    .products & {
        padding-left: 0;
    }

    &__item {
        display: inline;
        margin-right: 5px;

        .products & {
            margin-right: 10px;
        }

        > a {
            color: $primary-color;
            //text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            font-size: 14px;
            padding-left: 15px;
            position: relative;
            display: inline-block;

            .products & {
                font-size: 14px;
                padding-left: 20px;
            }
        }

        > a:before {
            font-family: 'fontAwesome';
            content: '\f02b';
            position: absolute;
            top: 0;
            left: 0;
        }

        > a.tag--file:before {
            content: '\f016';
        }
    }
}

.btn--app-fields {
    position: relative;
    display: block;
    margin-top: 30px;
    background-color: rgba(240,240,240,1);
    padding: 15px 20px;
    font-size: 20px;
    color: #333;

    &:hover {
        text-decoration: none;
        background-color: rgba(240,240,240,.8);
    }

    &:before {
        font-family: 'fontAwesome';
        content: '\f02b';
        color: #ccc;
        margin-right: 15px;
        /*position: absolute;
        top: 0;
        left: 0;*/
    }
}
