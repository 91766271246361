.cms-case-studies-table{
    border: none;

    img,picture{
        display: block;
        max-width: 100%;
    }
    thead{
        th{
            background-color: #f1f1f4;
            color: #000000;
            font-size: 18px;
            font-weight: 400;
        }
    }
    td{
        background-color: #ffffff;
        border-bottom: 1px solid  #f1f1f4;
        img{
            max-width: 100%;
        }
    }
}

