
.side-brands {
    &__filter {
        cursor: pointer;

        &.is-checked {
            font-weight: bold;
        }
    }
}
