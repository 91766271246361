$nav-prod-btn-padding: 3px 20px;

.nav-prod {
    vertical-align: middle;
    display: flex;
    flex-direction: row-reverse;

    &.fixed{
        position: fixed;
        left: 0px;
        top: 0;
        z-index: 20;
        width: 100%;
        padding: 9px 15px;
        flex-direction: row;
        border-bottom: 2px solid #cccccc;
        align-items: center;
        background-color:#f0f0f0;
        justify-content: space-between;

        @media (min-width: 1024px){
            justify-content: space-evenly;
        }

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background-color: rgba(#f0f0f0, 0.8);
            backdrop-filter: blur(10px);    
        }

        @media (min-width: 1024px){
            .nav-prod__menu{
                display: block;
            }
        }

        .nav-prod__product{
            display: block;
        }
        
        .nav-prod__cta{
            .btn{
                padding: 7px 11px;
                @media (min-width: 1280px){
                    padding: $nav-prod-btn-padding;
                }
            }

            span{
                display: none;
                @media (min-width: 1280px){
                    display: inline;
                }
            }
    
            i{
                display: inline-block;
                @media (min-width: 1280px){
                    display: none;
                }
            }
        }
    }

    &.for-landing {
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        padding: 0;

        &.fixed {
            opacity: 1;
            visibility: visible;

            // con height
            .nav-prod__wrapper {
                height: 70px;
            }
        }
    }

    // no height
    &__wrapper {
        width: 100%;
        padding: 0 30px;
        height: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }  

    &__product{
        color: #333333;
        display: none;
    } 

    &__category{
        display: block;
        font-size: 12px;
    }

    &__title{
        display: block;
        font-size: 22px;
    }

    &__menu{
        display: none;
    }

    &__cta{
        z-index: 22;
        i{
            display: none;
        }
    }

    ul{
        margin-bottom: 0;
    }

    li {
        list-style: none;
        display: inline-block;
        margin: 0px 8px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__item {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        transition: all 0.3s;

        &:hover {
            text-decoration: none !important;
            color: $primary-color !important;
            opacity: .8;
        }
    }

    .btn {
        cursor: pointer;
        color: white;
        font-size: 14px;
        text-transform: uppercase;
        height: auto;
        line-height: 22px;
        padding: $nav-prod-btn-padding;

        &:hover {
            color: $primary-color;
        }
    }    
}
