
// Colors
$white: #fff;
$black: #000;

$default: #333;
$primary-color: #033c73;
$secondary-color: #ccc;
$primary-rgb: 3, 60, 115;
$primary-hsl: 209 94.9% 23.1%;

    // Grey Scale
    $grey-light: #f0f0f0;
    $grey-bg: #f7f7f7;
    $grey-form: #fafafa;
    $grey-multi: #4a4a4a;
    $grey-border: #606060;
    $grey-foot: #9b9b9b;
    $grey-hover: #eaeaea;
    $table-grey-bg: #fcfcfc;

    // Categories
    $wood: #db9c15;
    $glass: #7ba0a1;
    $metal: #748092;
    $stone: #7e7b74;
    $plastic: #4e79b6;
    $advanced: #a1a17f;

    // Spot
    $bg-spot: rgba(193, 203, 223, 0.45);

    // Font
    $primary-font: 'Helvetica Neue','Helvetica',Arial, sans-serif;
    //$condensed-font: 'Oswald', sans-serif;
