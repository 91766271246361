.actions{
    background-color: $grey-light;
    padding: 3rem;
    margin: 2rem 0;

    &__item{
        display: block;
        text-align: center;
        margin-bottom: 2rem;

        > div:first-child{
            margin-bottom: 0.5rem;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}