
.current-product {
    margin-left: 0px;
    margin-top: 10px;

    li {
        padding: 7px 0;
        color: $secondary-color;
    }

    &__item {

    }
}

.site-sidebar__menu ul li ul {
    margin-left: 5px;
}

.s-prodlist {

    &__faq{
        padding-top: 10px;
        text-align: right;
        .btn{
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    @media (max-width: 767px){
        .spot-content--colored {
            margin-bottom: 30px;
        }
    }

    &__title {
        margin-bottom: 32px;
        display: inline-block;
    }

    &__info {
        //max-width: 80%;
        margin: 0px 0 64px;
        clear: both;

        @media (max-width: 767px){
            margin-bottom: 50px
        }

        p {
            margin-bottom: 20px;
        }
    }

    &__toolbar {
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 15px;
        padding: 0 0 10px;

        .anchor {
            margin-right: 15px;
        }

        .lbl {
            float: right;
        }

        ul {
            margin: 0;
            padding: 0;
            height: 30px;
            line-height: 30px;
            margin-left: 5px;
            float: right;
        }
    }

    .toolbar-item {
        cursor: pointer;
        display: inline-block;
        list-style: none;
        margin-left: 10px;
        height: 30px;
        text-align: center;

        .fa {
            font-size: 25px;
            color: $secondary-color;
            height: 30px;
        }

        &.active .fa {
            color: $default;
        }

        &:hover {
            opacity: .8;
        }
    }

    &__item {
        padding: 20px;
        border: 1px solid $secondary-color;
        position: relative;
        margin-bottom: 30px;
        min-height: 440px;

        .pimcore_area_product & {
            min-height: 385px;
        }

        .s-www_scmgroup_ru & {
            min-height: 450px;
        }

        @media (max-width: 767px){
            min-height: inherit;
        }

        .product-info & {
            min-height: inherit;

            h5 {
                word-wrap: break-word;
            }
        }

        @media (max-width: 1080px){
            padding: 15px;
        }

        &:hover {
            @include transition(border 0.25s ease-in-out);

            border-color: $default;
        }

        &.media {
            display: table!important;
            min-height: inherit;
        }

        .with-rows & {
            padding: 0;
            padding-bottom: 20px;
            margin-top: 20px;
            margin-bottom: 0;
            border: none;
            border-bottom: 2px solid $grey-light;

            &:hover {
                border-color: $secondary-color;
            }
        }

        img {
            margin-bottom: 37px;
        }

        h4 {
            margin-top: 0;
            display: block;
        }

        .info {
            color: $grey-foot;
            display: inline-block;
            margin-bottom: 13px;

            .product-info & {
                margin-bottom: 0;
            }

            .with-rows & {
                padding-left: 40px;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .text {
            width: 100%;
            margin: 0 0 1em 0;
        }
        .text > .mini-desc {
            margin: 0;
        }

        .media-heading {
            font-size: 22px;
            font-weight: 300;
            margin-bottom: 5px;
        }

        .media-heading > .info {
            margin-bottom: 0;
        }

        .media-object {
            width: 160px;
            margin-bottom: 0;
            max-width: inherit;
            padding-right: 40px;
        }

        .media-body {
            display: none;
        }

        .mini-desc p {
            text-align: inherit!important;
            strong,
            br {
                display: none;
            }
        }

        .mini-desc ul {
            list-style: none;
            margin: 0;
            padding: 0;

            > li {
                display: inline;
            }
        }

        .info img {
            margin-bottom: 0;
        }
    }

    &__grid {
        //display: none;

        //@include transition(all .25s ease-in-out);
        @media (max-width: 767px){
            margin: 0 15px;
        }

        .col {
            float: left;
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
        }
        &.with-cols .col {
            width: 33.333%;

            @media (max-width: 1080px) {
                width: 50%;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
        &.with-rows .col {
            width: 100%;
        }

        &.is-visible {
            display: block;
        }
    }
}

.product-mod {
    &__nav {
        ul {
            display: flex;
            padding-left: 0;
            margin-bottom: 30px;
            list-style: none;

            li {
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__item {
        &__title {
            background-color: $grey-light;
            color: $primary-color;
            padding: 35px 50px;
            margin-bottom: 50px;

            h3 {
                line-height: 0.71;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        &__content {
            margin-bottom: 50px;
            line-height: 1.56;
        }
    }
}
