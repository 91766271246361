.filters-wrapper{
    margin-bottom: 40px;
}

.filters {
    /*height: 40px;
    line-height: 40px;*/
    margin-top: 5px;

    &.mt-20{
        margin-top: 20px;
    }

    &--tab{
        margin-bottom: 0;
    }

    @media (max-width: 1200px){
        height: auto;
        line-height: inherit;
    }

    label {
        display: block;
        font-weight: 400;
        margin-bottom: 10px;
        margin-right: 13px;
        height: 40px;
        line-height: 40px;
    }

    ul {
        margin: 0;
        padding: 0;
        float: left;
        //margin-left: 13px;

        @media (max-width: 1200px){
            margin-top: 10px;
            clear: left;
        }
    }

    &__item {
        list-style: none;
        float: left;
        margin-right: 13px;

        &:last-child {
            margin-right: 0;
        }

        @media (min-width: 768px){
            margin-bottom: 13px;
        }

        @media (max-width: 767px) {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .btn {
        cursor: pointer;

        @media (max-width: 1200px){
            padding: 4px 10px;
        }
    }
    .btn--link.btn--filter{
        background: none;
        color: inherit;
    }
}

#list-case-studies {
    .map__finder {
        float: left;
    }

    .map__finder label {
        display: block;
    }
}