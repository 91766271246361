
.box-list {
    //min-height: 400px;

    &__spot {
        min-height: 400px;
        position: relative;
        margin-bottom: 30px;

        /*&.low {
            min-height: 400px;
        }*/

        .s-prodlist__cs & {
            min-height: 370px;

            .spot-image__img {
                aspect-ratio: 166/85;

                @supports not (aspect-ratio: 166/85) {
                    &::before {
                        float: left;
                        padding-top: 51.20%;
                        content: '';
                    }
                      
                    &::after {
                        display: block;
                        content: '';
                        clear: both;
                    }
                }
            }
        }

        @media (max-width: 767px){
            min-height: inherit;
        }

        &.spot--fairs {
            min-height: 420px;

            @media (max-width: 767px){
                min-height: inherit;
            }
        }

        .info {
            font-size: 14px;
            display: inline-block;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .info-loc {
            margin-bottom: 15px;
            margin-top: 10px; 
        }

        .info-loc img {
            margin-top: -2px;
            margin-right: 10px;
        }
    }
}

.content-list {
    &__text {
        margin-bottom: 50px;
    }
}

.grid .grid-item {
    @media (max-width: 767px){
        width: 100%;
    }
}
