
.cd-hero {
    display: table;
    width: 100%;
    background-color: $grey-light;
    position: relative;
    overflow: hidden;

    &.remove-hero {
        display: none;   
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
    }

    &-content {
        padding: 0 15px;
        display: table-cell;
        vertical-align: bottom;
        text-align: left;
        height: 250px;
        position: relative;
        z-index: 5;

        @media (max-width: 767px){
            height: 140px;
            padding: 0;
        }

        .small-height & {
            height: 200px;

            @media (max-width: 767px){
                height: 120px;
            }
        }

        .no-image & {
            height: 140px;
        }
    }

    &__title {
        margin-top: 3px;
        margin-bottom: 20px;
        color: $white;

        .no-image & {
            color: $default;
        }
    }

    &.default-height {
        height: 175px;

        @media (min-width: 992px){
            height: 250px;                
        }
    }

    &.small-height {
        height: 200px;

        @media (max-width: 767px){
            height: 155px;
        }
    }

    &.no-image {
        //height: 140px;
        height: 100px;
        background-color: transparent;

        &:after {
            background-color: transparent;
        }
    }

    &.with-content {
        height: auto!important;
    }

    //
    .breadcrumb {
        position: relative;
        z-index: 1;

        ul {
            padding: 0;
            margin: 0;
        }

        li {
            float: left;
            margin-left: 10px;
            list-style: none;

            &:after {
                color: $white;
                content: '/';
                margin-left: 10px;
            }

            .no-image & {
                &.after {
                    color: $default;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }
        }

        li > a {
            color: $white;

            .no-image & {
                color: $default;
            }
        }
    }

    &.no-image {
        .breadcrumb li:after,
        .breadcrumb li a {
            color: $default;
        }
    }
}

.pagination > li > a,
.pagination > li > span {
    padding: 6px 10px;
}
